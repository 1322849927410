import * as React from "react";
import { Card, Collapse, Typography } from "antd";
import { cx } from "utils";
import styles from "./Item.module.css";

interface ItemProps {
  className?: string;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  extra?: React.ReactNode;
  collapsable?: boolean;
}

const PANEL_KEY = "default";

const Item: React.FC<ItemProps> = ({
  className,
  title,
  subTitle,
  extra,
  children,
  collapsable = false,
}) => {
  const header =
    title || subTitle ? (
      <div className={styles.header}>
        <Typography.Title level={5} className={styles.title}>
          {title}
        </Typography.Title>
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      </div>
    ) : null;

  const collapse = (
    <Collapse className={styles.collapse} defaultActiveKey={[PANEL_KEY]} ghost>
      <Collapse.Panel key={PANEL_KEY} header={header} extra={extra}>
        {children}
      </Collapse.Panel>
    </Collapse>
  );

  const card = (
    <Card className={styles.card} title={header} extra={extra}>
      {children}
    </Card>
  );

  return (
    <div
      className={cx(styles.wrapper, className, {
        [styles.table]: !title,
      })}
    >
      {collapsable ? collapse : card}
    </div>
  );
};

export default Item;
