import * as React from "react";
import { Typography, Col, Row } from "components";
import st from "./Support.module.css";

const Support: React.FC = () => {
  return (
    <Row align="middle" justify="center" className={st.wrapper}>
      <Col>
        <Typography.Title level={4}>WE ARE HERE TO HELP!</Typography.Title>
        <Typography.Text>
          For assistance, please contact:{" "}
          <Typography.Link>
            kinesisfleet.support@radiustelematics.com
          </Typography.Link>
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default Support;
