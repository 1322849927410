import * as React from "react";
import { Spin } from "antd";
import styles from "./Loading.module.css";

const Loading: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <Spin />
    </div>
  );
};

export default Loading;
