import * as React from "react";
import { Routes, Route } from "components";

interface ResourceProps {
  list?: React.ExoticComponent;
  show?: React.ExoticComponent;
  edit?: React.ExoticComponent;
  create?: React.ExoticComponent;
}

const Resource: React.FC<ResourceProps> = ({ list, show, edit, create }) => (
  <Routes>
    {list && <Route index element={React.createElement(list)} />}
    {show && <Route path=":id/*" element={React.createElement(show)} />}
    {edit && <Route path=":id/edit/*" element={React.createElement(edit)} />}
    {create && <Route path="create/*" element={React.createElement(create)} />}
  </Routes>
);

export default Resource;
