import * as I from "@ant-design/icons";
import { matchPath } from "react-router-dom";
import { isAdminService } from "utils";
import { MenuItem } from "./types";

export const getNavigation = (): MenuItem[] => {
  if (isAdminService()) {
    return [
      {
        path: "/clients",
        title: "Client Accounts",
        icon: I.AppstoreOutlined,
      },
      {
        path: "/users",
        title: "User Management",
        icon: I.TeamOutlined,
      },
    ];
  }

  return [
    {
      path: "/",
      title: "Dashboard",
      icon: I.AppstoreOutlined,
    },
    {
      path: "/assets",
      title: "Assets",
      icon: I.FileDoneOutlined,
    },
    {
      title: "Schedule Setup",
      icon: I.CalendarOutlined,
      subMenu: [
        {
          path: "/schedule-templates",
          title: "Schedule Templates",
        },
        {
          path: "/service-steps",
          title: "Service Steps",
        },
        {
          path: "/tasks",
          title: "Tasks",
        },
        {
          path: "/task-groups",
          title: "Task Groups",
        },
      ],
    },
    {
      path: "/users",
      title: "User Management",
      icon: I.TeamOutlined,
    },
    {
      title: "Data Catalog",
      icon: I.ReconciliationOutlined,
      subMenu: [
        {
          path: "/make",
          title: "Make",
        },
        {
          path: "/model",
          title: "Model",
        },
        {
          path: "/region",
          title: "Region",
        },
        {
          path: "/location",
          title: "Location",
        },
        {
          path: "/category",
          title: "Category",
        },
      ],
    },
  ];
};

export const getSelectedKeys = (navigation: MenuItem[], pathname: string) => {
  const selectedKeys: string[] = [];

  const selectActivePath = (items: MenuItem[]) => {
    items.forEach((item) => {
      if (item.path) {
        let isActive;

        if (item.path === "/") {
          const homePagePaths = ["/maintenances", "/services"];
          isActive = homePagePaths.includes(pathname);
        } else {
          isActive = matchPath(`${item.path}/*`, pathname);
        }

        if (isActive) {
          selectedKeys.push(item.title);
        }
      }

      if (item.subMenu) {
        selectActivePath(item.subMenu);
      }
    });
  };

  selectActivePath(navigation);
  return selectedKeys;
};

export const getOpenKeys = (navigation: MenuItem[], selectedKeys: string[]) => {
  return navigation
    .filter((item) =>
      item.subMenu?.some((subItem) =>
        selectedKeys.some((key) => subItem.title === key)
      )
    )
    .map((item) => item.title);
};
