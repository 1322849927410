import * as React from "react";
import { CountryFlag, Select } from "components";
import { PHONE } from "consts";
import styles from "./CountryCodeSelect.module.css";

interface CountryCodeSelect {
  value: string;

  onChange(value: string): void;
}

const OPTIONS = Object.values(PHONE.countries).map((c) => ({
  value: c.isoCode,
  label: (
    <div
      className={styles.dropdown}
      key={`${c.name} ${c.code} ${c.isoCode}`}
      title={`${c.name} ${c.code}`}
    >
      {/* eslint-disable-next-line css-modules/no-undef-class */}
      <CountryFlag isoCode={c.isoCode} className={styles.flag} />{" "}
      {/* eslint-disable-next-line css-modules/no-undef-class */}
      <span className={styles.name}>{c.name}</span>
      {/* eslint-disable-next-line css-modules/no-undef-class */}
      <span className={styles.code}>{c.code}</span>
    </div>
  ),
}));

const CountryCodeSelect: React.FC<CountryCodeSelect> = ({
  value,
  onChange,
}) => {
  return (
    <>
      <Select
        value={value}
        onChange={onChange}
        className={styles.countryCodeSelect}
        options={OPTIONS}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={(input, option) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          option?.label?.key.toLowerCase().indexOf(input.toLowerCase()) !== -1
        }
        onSelect={() => (document.activeElement as HTMLElement).blur()}
      />
    </>
  );
};

export default CountryCodeSelect;
