import { WorkOrderStatusEnum } from "types";

export const statusOptions = [
  {
    value: WorkOrderStatusEnum.Pending,
    label: "Pending",
  },
  {
    value: WorkOrderStatusEnum.Open,
    label: "Open",
  },
  {
    value: WorkOrderStatusEnum.InProgress,
    label: "In Progress",
  },
];
