import * as React from "react";
import { ColumnProps } from "antd/lib/table";

const ActionsColumn: React.FC<
  // TODO: Add posibility to pass generic like for default Column component.
  // Here can be any type of record.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Omit<ColumnProps<any>, "title" | "dataIndex"> & {
    title?: string;
    dataIndex?: string;
  }
> = () => <></>;

ActionsColumn.defaultProps = {
  sorter: false,
  title: "Action",
  align: "center",
  width: 122,
};

export default ActionsColumn;
