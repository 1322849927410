import { useQuery as useNativeQuery } from "@apollo/client";
import useOnError from "./use-on-error";

const useQuery: typeof useNativeQuery = (query, options) => {
  const onError = useOnError(options?.onError);

  return useNativeQuery(query, {
    ...options,
    onError,
    fetchPolicy: "cache-and-network",

    // https://github.com/apollographql/react-apollo/issues/3709#issuecomment-592786578
    notifyOnNetworkStatusChange: true,
  });
};

export default useQuery;
