import * as React from "react";
import { FormikProps } from "formik";

const useFormikOutside = <
  TFormValues extends Record<string, any> = Record<string, unknown>
>(): {
  formRef: React.Ref<FormikProps<TFormValues>>;
  handleSubmit: () => void;
} => {
  const formRef = React.useRef<FormikProps<TFormValues>>(null);
  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return {
    formRef,
    handleSubmit,
  };
};

export default useFormikOutside;
