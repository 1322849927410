import * as React from "react";
// TODO: Use another lib? E.g. `qs` or `query-string`.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as JSURL from "jsurl";
import type { NavigateOptions } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

export default function useQueryParam<T>(
  key: string
): [T | undefined, (newQuery: T | null, options?: NavigateOptions) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);

  const value = React.useMemo(() => {
    let result;
    try {
      result = JSURL.parse(paramValue);
    } catch {}
    return result || undefined;
  }, [paramValue]);

  const setValue = (newValue: T | null, options?: NavigateOptions) => {
    const newSearchParams = new URLSearchParams(window.location.search);
    if (newValue === null) {
      newSearchParams.delete(key);
    } else {
      newSearchParams.set(key, JSURL.stringify(newValue));
    }
    setSearchParams(newSearchParams, options);
  };

  return [value, setValue];
}
