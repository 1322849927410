import React from "react";
import { TooltipItem } from "chart.js";
import { Chart as ChartJS } from "react-chartjs-2";
import { Typography } from "components";
import { Types } from "../../duck";
import { utils } from "./duck";
import styles from "./Bar.module.css";

interface BarProps {
  data: Types.DataChart;
}

const Bar: React.FC<BarProps> = ({ data }) => {
  const { nodes, title } = data;

  return (
    <div className={styles.wrapper}>
      {title && (
        <div className={styles.title}>
          <Typography>{title}</Typography>
        </div>
      )}
      <div className={styles.chart}>
        <ChartJS
          type="bar"
          data={{
            labels: nodes.map((item) => item.id),
            datasets: [
              {
                label: "Bar",
                data: nodes.map((item) => item.value),
                backgroundColor: nodes.map((item) => item.color),
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                ticks: {
                  stepSize: utils.getStepsSize(nodes),
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  // TODO: remove any type if it possible
                  label(tooltipItem: TooltipItem<any>): string | string[] {
                    return `${tooltipItem.label}: ${tooltipItem.formattedValue}`;
                  },
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default Bar;
