import { Filter, Order, Pagination, Sort, Search } from "types";

export const prepareVariables = <TVariables>(
  pagination: Pagination,
  sortBy: Sort | undefined | null,
  filter: Filter | undefined | null,
  search: Search | undefined | null,
  orderBy: Order | undefined | null,
  variables: TVariables
) => {
  return {
    pageNumber: pagination.number,
    pageSize: pagination.size,
    sortBy,
    orderBy,
    filter,
    search: search
      ?.replace(/ /g, "")
      ?.match(
        /^(\+{0,})(\d{0,})([(]{0,}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){0,}([\s|-]?\d+){0,}([\s|-]?\d+){0,2}(\s){0,}$/g
      )
      ? search?.replace(/[-()+ ]+/g, "")
      : search,
    ...variables,
  };
};
