import * as React from "react";
import { ApolloError } from "@apollo/client";
import { message, Modal, Upload, UploadProps } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { Canceler } from "axios";
import { ImagePreviewModal } from "components";
import { useField, useMutation } from "hooks";
import { operations, Types, utils } from "./duck";

interface FormUploadProps extends Omit<UploadProps, "onChange" | "fileList"> {
  name?: string;
  sizeLimit?: number;
  fileList?: Types.UploadFile[];
  onChange?: (fileList: Types.UploadFile[]) => void;
  children?: React.ReactNode;
  withoutRequest?: boolean;
  preview?: boolean;
  confirmMessage?: string;
}

const confirmRemove = (content: string) =>
  new Promise<boolean>((resolve) =>
    Modal.confirm({
      title: "Confirm",
      content,
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
      okText: "Yes",
      cancelText: "No",
    })
  );

const FormUpload: React.FC<FormUploadProps> = ({
  name = "",
  sizeLimit = 104_857_600, // 100 MB
  accept,
  onChange: extOnChange,
  fileList: extFileList,
  onRemove: extOnRemove,
  children,
  withoutRequest = false,
  preview: extPreview = false,
  confirmMessage = "Are you sure you want to delete this file?",
  ...props
}) => {
  const [preview, setPreview] = React.useState<null | {
    title: string;
    imageUrl: string;
  }>(null);

  const [{ value = [] }, , { setValue, setTouched }] = useField<
    Types.UploadFile[] | undefined
  >(name);

  const fileList = extFileList || value;
  const fileListRef = React.useRef(fileList);
  const setFileList = (newFileList: Types.UploadFile[]) => {
    fileListRef.current = newFileList;
    (extOnChange || setValue)(newFileList);
  };

  React.useEffect(() => {
    if (fileListRef.current !== fileList) {
      fileListRef.current = fileList;
    }
  }, [fileList]);

  const [uploadFiles] = useMutation<
    Types.UploadDirectsMutation,
    Types.UploadDirectsMutationVariables,
    Types.MutationContext
  >(operations.uploadDirects);

  const updateFile = (
    uid: string,
    newFile: Partial<Types.UploadFile>,
    files: Types.UploadFile[]
  ) => {
    setFileList(
      files.map((file) => (file.uid === uid ? { ...file, ...newFile } : file))
    );
  };

  // https://github.com/ant-design/ant-design/issues/2423
  const onChange = ({
    file,
    fileList: files,
  }: UploadChangeParam<Types.UploadFile>) => {
    if (!file.status) {
      return;
    }

    switch (file.status) {
      case "error":
        return updateFile(file.uid, { response: file.error.message }, files);
      case "done":
        return updateFile(
          file.uid,
          { url: file.response?.url, thumbUrl: file.response?.url },
          files
        );
      default:
        setFileList(files);
        setTouched(true, false);
    }
  };

  const beforeUpload = (file: Types.RcFile): boolean => {
    if (accept && !utils.validateMimeType(file, accept)) {
      message.error(
        `Sorry. This file format is not accepted. We can accept “${accept}“ files only.`
      );

      return false;
    }

    if (file.size > sizeLimit) {
      message.error(
        `Please note that the maximum size of each uploaded file is ${utils.formatSize(
          sizeLimit
        )}.`
      );

      return false;
    }

    return true;
  };

  const customRequest: UploadProps["customRequest"] = async ({
    file,
    onSuccess,
    onError,
    onProgress,
  }) => {
    if (withoutRequest) {
      return onSuccess?.(null, null as any);
    }

    if (typeof file === "object" && file instanceof File) {
      let abort: undefined | Canceler;

      uploadFiles({
        variables: { files: [file] },
        context: {
          fetchOptions: {
            onProgress: (event) => {
              const found = fileListRef.current.find(
                ({ uid }) => file.uid === uid
              );

              if (found) {
                const percent = (event.loaded / event.total) * 100;
                onProgress?.({ ...event, percent });
              } else {
                abort?.();
              }
            },
            onAbort: (canceler) => {
              abort = canceler;
            },
          },
        },
      })
        .then(({ data }) => {
          return onSuccess?.(
            data?.createDirectApolloUpload.directUpload[0],
            /*
                      In docs should accept file and shouldn't be required.
                      https://github.com/ant-design/ant-design/issues/2423
                  */
            file as any
          );
        })
        .catch((error) => {
          if (error instanceof ApolloError) {
            onError?.(error, error.message);
          }
        });
    }
  };

  const onPreview = async (file: Types.UploadFile) => {
    setPreview({
      title: file.name,
      imageUrl: file.url || "",
    });
  };

  const onRemove = (file: Types.UploadFile) => {
    if (extOnRemove) {
      return extOnRemove(file);
    }

    return confirmRemove(confirmMessage);
  };

  return (
    <>
      <Upload
        {...props}
        fileList={fileList}
        customRequest={customRequest}
        onChange={onChange}
        beforeUpload={beforeUpload}
        onPreview={extPreview ? onPreview : undefined}
        accept={accept}
        onRemove={onRemove}
      >
        {children}
      </Upload>
      {extPreview && (
        <ImagePreviewModal
          title={preview?.title}
          visible={!!preview}
          onCancel={() => setPreview(null)}
          imageSrc={preview?.imageUrl}
        />
      )}
    </>
  );
};

export default FormUpload;
