import React from "react";
import { Typography } from "components";
import { Types } from "../../duck";
import styles from "./Legend.module.css";

interface LegendProps {
  data: Types.NodeChart[];
  total?: number;
}

const Legend: React.FC<LegendProps> = ({ data, total }) => {
  return (
    <>
      {data.map((item) => (
        <div className={styles.item} key={item.id}>
          <div
            className={styles.mark}
            style={{ backgroundColor: item.color }}
          />

          <div className={styles.label}>
            <span>{item.id}</span>
          </div>

          <div className={styles.count}>
            <Typography>{`${item.value}/${
              item.value && total
                ? Math.round((100 / total) * item.value * 10) / 10
                : 0
            }%`}</Typography>
          </div>
        </div>
      ))}
    </>
  );
};

export default Legend;
