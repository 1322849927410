import * as React from "react";
import { Typography } from "components";

interface DownloadProps {
  url: string;
}

const Download: React.FC<DownloadProps> = ({ url }) => {
  return (
    <Typography.Text>
      STEP 1: Download Assets Template{" "}
      <a href={url} target="_blank" rel="noreferrer">
        here
      </a>
    </Typography.Text>
  );
};

export default Download;
