import * as React from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import moment from "moment";
import * as C from "components";
import { useNavigate, useChildPath, useQuery } from "hooks";
import { QueryObject, Sort, Order, Search, DirectionEnum, Filter } from "types";
import * as L from "./components";
import { Types, operations } from "./duck";
import styles from "./Home.module.css";

interface HomeStatic {
  Dashboard: typeof L.Dashboard;
  Services: typeof L.Services;
}

const LIST_PROPS: {
  [key: string]: {
    query: QueryObject & { key: string };
    defaultSort?: Sort;
    defaultOrder?: Order;
  };
} = {
  maintenances: {
    query: {
      operation: operations.getMaintenances,
      key: "maintenances",
    },
    defaultOrder: { rateTillDue: DirectionEnum.Asc },
  },

  services: {
    query: {
      operation: operations.getDashboardServices,
      key: "dashboardServices",
    },
    defaultOrder: { deadline: DirectionEnum.Asc },
  },
};

const Home: React.FC & HomeStatic = () => {
  const [isWidgetDrawerVisible, setIsWidgetDrawerVisible] =
    React.useState(false);
  const navigate = useNavigate();
  const childPath = useChildPath();
  const searchRef = React.useRef<Search | null | undefined>();
  const filterRef = React.useRef<Filter | null | undefined>();

  const { data } = useQuery<Types.GetExtraQuery>(operations.getExtra);
  // TODO: query with filters
  const hasOverdueService = !!data?.services.nodes.find(
    ({ deadline, asset, workOrder }) =>
      moment().isAfter(moment(deadline)) && !asset.disabled && !workOrder.closed
  );

  return (
    <C.Content
      title="Dashboard"
      subTitle={
        /** no access to List context with filters, setFilters. So pass queryKey and set queryParams directly */
        data && (
          <L.Filters
            queryKey={LIST_PROPS[childPath].query.key}
            userRegions={data.userRegions.nodes}
            userLocations={data.userLocations.nodes}
          />
        )
      }
      extra={<L.EditWidgets onClick={() => setIsWidgetDrawerVisible(true)} />}
      backPath={null}
    >
      <L.Widgets
        isDrawerVisible={isWidgetDrawerVisible}
        setIsDrawerVisible={setIsWidgetDrawerVisible}
      />

      <C.List key={childPath} {...LIST_PROPS[childPath]}>
        {({ paramsCache, search, filter }) => {
          searchRef.current = search;
          filterRef.current = filter;

          return (
            <C.Content.Item className={styles.contentItem}>
              <C.Tabs
                type="card"
                onChange={(path) => {
                  const nextQueryKey = LIST_PROPS[path].query.key;
                  /** share search between tabs:
                   * search from List is not used because
                   * of delay between Search input change and queryParams applying.
                   * So fast user goes to other tab before
                   * queryParams is applied he'll get previous search value
                   */
                  paramsCache.set(nextQueryKey, {
                    ...paramsCache.get(nextQueryKey),
                    search: searchRef.current,
                    filter: filterRef.current,
                  });
                  if (path !== childPath) navigate(path, { replace: true });
                }}
                defaultActiveKey={childPath}
                tabBarExtraContent={
                  <C.Search
                    onChange={(value) => {
                      searchRef.current = value;
                    }}
                  />
                }
              >
                <C.Tabs.TabPane key="maintenances" tab="Maintenance">
                  <C.Outlet />
                </C.Tabs.TabPane>

                <C.Tabs.TabPane
                  key="services"
                  tab={
                    <>
                      {hasOverdueService && (
                        <C.Typography.Text type="danger">
                          <ExclamationCircleFilled />
                        </C.Typography.Text>
                      )}
                      Services
                    </>
                  }
                >
                  <C.Outlet />
                </C.Tabs.TabPane>
              </C.Tabs>
            </C.Content.Item>
          );
        }}
      </C.List>
    </C.Content>
  );
};

Home.Dashboard = L.Dashboard;
Home.Services = L.Services;

export default Home;
