import * as React from "react";
import * as C from "components";
import { useQueryParam } from "hooks";
import { QueryParam } from "types";
import { UserLocations, UserRegions } from "../../duck/types";
import * as L from "./components";
import { utils, Types } from "./duck";
import styles from "./Filters.module.css";

interface FiltersProps {
  queryKey: string;
  userRegions: UserRegions;
  userLocations: UserLocations;
}

const Filters: React.FC<FiltersProps> = ({
  queryKey,
  userRegions,
  userLocations,
}) => {
  const [params, setParams] = useQueryParam<QueryParam>(queryKey);

  return (
    <C.Formik<Types.FormValues>
      initialValues={{
        regionId: (params?.filter?.equal?.regionId as string) || null,
        locationId: (params?.filter?.equal?.locationId as string) || null,
      }}
      onSubmit={(values) => {
        const filter = utils.prepareFilter(values, params?.filter || {});
        setParams({ ...params, filter });
      }}
    >
      {({ values }) => {
        return (
          <div className={styles.wrapper}>
            <C.Form.Item name="regionId" label="Region:">
              <L.Select
                name="regionId"
                options={
                  userRegions.map(({ region }) => ({
                    label: region.name,
                    value: region.id,
                  })) || []
                }
              />
            </C.Form.Item>

            <C.Form.Item name="locationId" label="Location:">
              <L.Select
                name="locationId"
                options={
                  (values.regionId &&
                    userLocations
                      .filter(({ location }) =>
                        values.regionId
                          ? location.region.id === values.regionId
                          : true
                      )
                      .map(({ location }) => ({
                        label: location.name,
                        value: location.id,
                      }))) ||
                  []
                }
              />
            </C.Form.Item>
          </div>
        );
      }}
    </C.Formik>
  );
};

export default Filters;
