import * as React from "react";
import { Form } from "components";
import { INPUT_MAX_LENGTH } from "consts";

const Name: React.FC = () => {
  return (
    <Form.Item name="name" required label="Task Name">
      <Form.Input
        name="name"
        placeholder="Name"
        maxLength={INPUT_MAX_LENGTH.NAME}
      />
    </Form.Item>
  );
};

export default Name;
