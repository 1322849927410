import React from "react";
import { Chart } from "react-chartjs-2";
import { Typography } from "components";
import { Types } from "../../duck";
import styles from "./HalfDoughnuts.module.css";

interface HalfDoughnutProps {
  data: Types.DataChart;
}

const HalfDoughnut: React.FC<HalfDoughnutProps> = ({ data }) => {
  const { nodes, title } = data;

  return (
    <div className={styles.wrapper}>
      <div className={styles.chart}>
        <div className={styles.title}>
          <Typography>{title}</Typography>
        </div>
        <Chart
          type="doughnut"
          data={{
            labels: nodes.map((item) => item.id),
            datasets: [
              {
                label: "HalfDoughnut",
                data: nodes,
                offset: 0,
                backgroundColor: nodes.map((item) => item.color),
                borderWidth: 0,
                parsing: {
                  key: "value",
                },
              },
            ],
          }}
          options={{
            aspectRatio: 1,
            responsive: true,
            cutout: "50%",
            offset: 4,
            plugins: {
              tooltip: {
                enabled: false,
              },
              legend: {
                display: false,
              },
            },
            rotation: 270,
            circumference: 180,
          }}
        />
        <div className={styles.subTitle}>
          <Typography className={styles.subText}>{data.subTitle}</Typography>
        </div>
      </div>
    </div>
  );
};

export default HalfDoughnut;
