import * as React from "react";
import { Resource } from "components";

const edit = React.lazy(() => import("./components/edit"));

const WorkOrder: React.FC = () => {
  return <Resource edit={edit} />;
};

export default WorkOrder;
