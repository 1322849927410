import * as React from "react";
import * as C from "components";
import { FormItemProps } from "../../duck/types";
import styles from "./Active.module.css";

const Active: React.FC<FormItemProps> = ({ md }) => {
  return (
    <C.Col className={styles.wrapper} span={24} md={md}>
      <>
        <C.Typography.Text strong className={styles.label}>
          Active
        </C.Typography.Text>
        <C.Form.Switch name="active" />
      </>
    </C.Col>
  );
};

export default Active;
