import {
  DirectionEnum,
  Order,
  ParsedOrder,
  PreparedSort,
  TableSort,
} from "types";

export const parseOrder = (order: Order): ParsedOrder => {
  const direction = Object.values(order)[0];
  const field = Object.keys(order)[0];

  return {
    [field]: direction === DirectionEnum.Asc ? "ascend" : "descend",
  };
};

export const prepareSort = (
  tableSort: TableSort | TableSort[]
): PreparedSort => {
  const sorts = Array.isArray(tableSort) ? tableSort : [tableSort];

  return sorts.reduce<PreparedSort>((acc, { field, order }) => {
    const fieldName = Array.isArray(field) ? field[field.length - 1] : field;

    if (field && order) {
      acc.order = {
        [fieldName]:
          order === "ascend" ? DirectionEnum.Asc : DirectionEnum.Desc,
      };
    }

    return acc;
  }, {});
};
