import { UploadFile } from "antd/lib/upload/interface";
import { Attachment } from "../schema.generated";

export const attachmentToFile = ({
  id,
  url,
  filename,
  signedBlobId,
}: Pick<
  Attachment,
  "id" | "filename" | "signedBlobId" | "url"
>): UploadFile => ({
  uid: id,
  url: url || undefined,
  thumbUrl: url || undefined,
  name: filename,
  response: { signedBlobId },
});
