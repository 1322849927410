import * as React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useSize } from "hooks";
import { cx } from "utils";
import styles from "./Scrollbar.module.css";

interface ScrollbarProps {
  className?: string;
}

const Scrollbar: React.FC<ScrollbarProps> = ({ children, className }) => {
  const [content, { width, height }] = useSize(() => <div>{children}</div>);

  React.useEffect(() => {
    /** `react-custom-scrollbars` doesn't have `update` method, so kinda hack there: */
    window.dispatchEvent(new Event("resize"));
  }, [width, height]);
  return (
    <Scrollbars
      className={cx(className, styles.scrollbarContainer)}
      renderTrackVertical={({ style, ...props }) => {
        return (
          <div
            {...props}
            style={{ ...style, width: "2px" }}
            className={styles.trackVertical}
          />
        );
      }}
    >
      {content}
    </Scrollbars>
  );
};

export default Scrollbar;
