import useAuth from "../use-auth";
import { consts, Types } from "./duck";

export default (): Types.Policies | null => {
  // use only if getting policies from back is impossible
  // fill consts with missing restrictions
  const [auth] = useAuth();

  return auth.user && consts.policies[auth.user.role.id];
};
