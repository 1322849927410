import * as React from "react";
import { Route, Routes, Spin } from "components";
import { useAuth } from "hooks";
import * as P from "pages";
import { AuthRoutes, Layout, NotAuthRoutes } from "./components";
import styles from "./AppRoutes.module.css";

const AppRoutes: React.FC = () => {
  const [auth] = useAuth();

  if (auth.isAuthenticating) {
    return (
      <div className={styles.overlay}>
        <Spin />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="graphiql" element={<P.Graphiql />} />
      <Route element={<Layout />}>
        <Route
          path="*"
          element={auth.isLoggedIn ? <AuthRoutes /> : <NotAuthRoutes />}
        />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
