import * as Types from "./types";

export const getWidgetSettings = (
  data?: Types.GetWidgetSettings,
  previousData?: Types.GetWidgetSettings
): Types.WidgetsConfig | null => {
  if (data) {
    return "adminUser" in data
      ? data.adminUser.widgetSettings
      : data.clientUser.widgetSettings;
  }

  if (previousData) {
    return "adminUser" in previousData
      ? previousData.adminUser.widgetSettings
      : previousData.clientUser.widgetSettings;
  }

  return null;
};
