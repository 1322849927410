import * as React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { ApolloError } from "@apollo/client";
import { UploadFile } from "antd/lib/upload/interface";
import { Button, Formik, message, Modal, SubmitButton } from "components";
import { useMutation } from "hooks";
import { MutationObject, FileImport } from "types";
import { Done, Download, Import } from "./components";
import { consts, Types } from "./duck";

interface BulkUploadProps {
  title: string;
  onSuccess: () => void;
  mutation: MutationObject & { key: string };
  downloadUrl: string;
}

function BulkUpload<
  TData extends Record<
    string,
    { fileImport: Pick<FileImport, "uploadedCount" | "notUploadedCount"> }
  >
>({
  title,
  onSuccess,
  mutation,
  downloadUrl,
}: BulkUploadProps): React.ReactElement {
  const [visible, setVisible] = React.useState(false);
  const [step, setStep] = React.useState<Types.Step>({ type: "download" });
  const [importAsset] = useMutation<TData, { file: any }>(mutation.operation);

  return (
    <>
      <Button
        type="primary"
        icon={<UploadOutlined />}
        onClick={() => setVisible(true)}
      >
        Bulk Upload
      </Button>

      <Formik<{ files: UploadFile[] }>
        initialValues={consts.validationSchema.getDefault()}
        validationSchema={consts.validationSchema}
        onSubmit={async ({ files: [file] }) => {
          try {
            const { data } = await importAsset({
              variables: { file: file.originFileObj },
            });

            if (data) {
              onSuccess();
              setStep({ type: "done", data: data[mutation.key].fileImport });
            }
          } catch (e) {
            if (e instanceof ApolloError) {
              message.error(e);
            }
          }
        }}
      >
        {({ handleSubmit, resetForm, setFieldTouched }) => {
          const close = () => {
            setVisible(false);
            setStep({ type: "download" });
            resetForm();
          };

          return (
            <Modal
              title={title}
              visible={visible}
              onCancel={close}
              footer={[
                step.type !== "done" && <Button onClick={close}>Cancel</Button>,
                step.type === "download" && (
                  <Button
                    type="primary"
                    onClick={() => setStep({ type: "upload" })}
                  >
                    Next
                  </Button>
                ),
                step.type === "upload" && (
                  <SubmitButton
                    type="primary"
                    onClick={() => {
                      handleSubmit();
                      /*
                      https://github.com/formium/formik/issues/3344
                      https://github.com/formium/formik/issues/1942
                      ---
                      Form.Item doesn't show the error, because it sets "touched" to false.
                      It handles "touched" incorrectly when it is an empty array.
                      Check: https://github.com/jannikbuschke/formik-antd/blob/release/2.0/src/form-item/index.tsx#L28
                      */
                      setFieldTouched("files", true);
                    }}
                  >
                    Submit
                  </SubmitButton>
                ),
                step.type === "done" && (
                  <Button type="primary" onClick={close}>
                    Done
                  </Button>
                ),
              ]}
            >
              {step.type === "download" && <Download url={downloadUrl} />}
              {step.type === "upload" && <Import />}
              {step.type === "done" && <Done {...step.data} />}
            </Modal>
          );
        }}
      </Formik>
    </>
  );
}

export default BulkUpload;
