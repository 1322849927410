import * as React from "react";
import { Layout } from "antd";
import { Outlet, Scrollbar } from "components";
import { useAuth } from "hooks";
import { cx } from "utils";
import { AppHeader, Navigation, CookiePolicy } from "./components";
import styles from "./Layout.module.css";

const AppLayout: React.FC = () => {
  const [auth] = useAuth();

  return (
    <Layout className={styles.wrapper}>
      <Layout.Header>
        <AppHeader />
      </Layout.Header>
      <Layout>
        {auth.isLoggedIn && (
          <Layout.Sider className={styles.aside} theme="light" breakpoint="lg">
            <Scrollbar>
              <Navigation />
            </Scrollbar>
          </Layout.Sider>
        )}
        <Layout.Content
          className={cx(
            styles.content,
            !auth.isLoggedIn && styles.loginContent
          )}
        >
          <React.Suspense fallback={null}>
            <Outlet />
          </React.Suspense>
        </Layout.Content>
      </Layout>

      <Layout.Footer className={styles.footer}>
        <CookiePolicy />
      </Layout.Footer>
    </Layout>
  );
};

export default AppLayout;
