import * as React from "react";
import { Form } from "components";
import { operations } from "./duck";

const Group: React.FC = () => {
  return (
    <Form.Item name="taskGroupIds" label="Select Task Group">
      <Form.Select
        optionValue="id"
        optionLabel="name"
        query={{
          operation: operations.getTaskGroupsOptions,
          key: "taskGroups",
        }}
        name="taskGroupIds"
        mode="multiple"
        placeholder="Task Group"
      />
    </Form.Item>
  );
};

export default Group;
