import * as React from "react";
import * as C from "components";
import { useQuery } from "hooks";
import { WidgetFC } from "../../duck/types";
import Widget from "../widget";
import { Preview } from "./components";
import { operations, Types, consts } from "./duck";

const WorkOrderByStatus: WidgetFC = () => {
  const { data, loading } = useQuery<
    Types.GetWorkOrderByStatusQuery,
    Types.GetWorkOrderByStatusQueryVariables
  >(operations.getWorkOrderByStatus);

  const { total = 0, ...statisticData } = data?.widgetWorkOrdersByStatus || {};

  const fetchData = statisticData
    ? Object.entries(statisticData)
        // TODO: remove typename case
        .filter(([label]) => label !== "__typename")
        .map(([label, value]) => {
          return {
            id: consts.labelsByStatus[label],
            color: consts.colorsByStatus[label],
            value,
          };
        })
    : [];

  return (
    <Widget title="Work Orders by Status" loading={loading}>
      <C.Chart
        type="doughnut"
        data={{ nodes: fetchData, total, title: `${total}` }}
      />
    </Widget>
  );
};

WorkOrderByStatus.Preview = Preview;

export default WorkOrderByStatus;
