import * as React from "react";
import { FilePdfOutlined } from "@ant-design/icons";

interface PdfLinkProps {
  id: string;
  url: string;
}

const PdfLink: React.FC<PdfLinkProps> = ({ id, url }) => {
  return (
    <a
      target="_blank"
      href={url}
      download={`Work Order ${id}`}
      rel="noreferrer"
    >
      <FilePdfOutlined /> {id}
    </a>
  );
};

export default PdfLink;
