import * as React from "react";
import * as C from "components";
import { useFormikContext, useQuery } from "hooks";
import { FormItemProps, FormValues } from "../../duck/types";
import { operations, Types, utils } from "./duck";

const RegionLocation: React.FC<
  FormItemProps & { variables?: { clientId: string } }
> = ({ md, variables }) => {
  const {
    values: { regionsLocations, allRegions },
    setFieldValue,
  } = useFormikContext<FormValues>();

  const { data, loading } = useQuery<
    Types.GetRegionsAndLocationsForUsersQuery,
    Types.GetRegionsAndLocationsForUsersQueryVariables
  >(operations.getRegionsAndLocationsForUsers, { variables });

  const regions = React.useMemo(() => {
    if (data) return data.regions.nodes;
    return [];
  }, [data]);

  const values = React.useMemo(
    () => (allRegions ? utils.getPlacesId(regions) : regionsLocations),
    [regions, allRegions, regionsLocations]
  );

  React.useEffect(() => {
    if (!regionsLocations) {
      setFieldValue("regionsLocations", values);
    }
  }, [setFieldValue, regionsLocations, values]);

  const tree = React.useMemo((): Types.DataNode[] => {
    if (!data) return [];

    return utils.generateTreeData(regions);
  }, [data, regions]);

  return (
    <C.Col span={24} md={md}>
      <C.Form.Item
        name="regionsLocations"
        label="Region/Location"
        required
        isArray
      >
        <C.Form.TreeSelect
          name="regionsLocations"
          treeData={tree}
          showCheckedStrategy={C.TreeSelect.SHOW_CHILD}
          loading={loading && !data}
          treeNodeFilterProp="title"
          onChange={(value) =>
            setFieldValue("allRegions", utils.isAllRegions(value, regions))
          }
          treeCheckable
          treeDefaultExpandAll
          getPopupContainer={(trigger) => trigger.parentNode}
          allowClear
        />
      </C.Form.Item>
    </C.Col>
  );
};

export default RegionLocation;
