import * as yup from "yup";
import { DEFAULT_PHONE_MIN_LENGTH, PHONE } from "consts";
import { Credentials } from "./types";

export const initialValues: Credentials = {
  code: "",
  phoneNumber: "",
  email: "",
};

export const validationSchema = yup.lazy((values: Credentials) => {
  const { phoneNumber, email } = values;
  const phoneDisabled = !!email;
  const emailDisabled = !!phoneNumber;
  const phoneMask = PHONE.masks[values.code];
  const phoneLength = phoneMask
    ? phoneMask.replace(/[^.]/g, "").length
    : DEFAULT_PHONE_MIN_LENGTH;

  const emailSchema = yup.string().trim().email("Invalid Email");
  const phoneSchema = yup
    .string()
    .test(
      "length",
      `Phone number must contain ${phoneLength} characters`,
      (val) => {
        if (!val) return false;
        return val.replace(/[^\d]/g, "").length >= phoneLength;
      }
    );

  return yup.object({
    phoneNumber: phoneDisabled ? yup.mixed() : phoneSchema,
    email: emailDisabled ? yup.mixed() : emailSchema,
  });
});
