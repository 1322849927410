import * as React from "react";
import GraphiQL, { Fetcher } from "graphiql";
import "graphiql/graphiql.css";
import styles from "./Graphiql.module.css";

const fetcher: Fetcher = (graphQLParams) =>
  fetch(process.env.REACT_APP_API_URL || "", {
    method: "post",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }),
    body: JSON.stringify(graphQLParams),
  })
    .then((response) => response.json())
    .catch(() => {});

const Graphiql: React.FC = () => (
  <div className={styles.wrapper}>
    <GraphiQL fetcher={fetcher} />
  </div>
);

export default Graphiql;
