import * as React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { FormikProps } from "formik";
import * as C from "components";
import { useMutation, useParams, useNavigate } from "hooks";
import { isAdminService } from "utils";
import * as L from "./components";
import { Types, consts, operations } from "./duck";

interface UserFormProps {
  innerRef: React.Ref<FormikProps<Types.FormValues>>;
  onSubmit: (data: any) => void;
  initialValues?: Types.FormValues;
  columnNumber?: 1 | 2 | 3;
  wrapperConfig?: {
    title: string;
  };
  isEditActiveField?: boolean;
  roleVariables?: {
    admin: boolean;
  };
  policies?: {
    delete: boolean;
  };
}

const UserForm: React.FC<UserFormProps> = ({
  initialValues = consts.initialValues,
  onSubmit,
  innerRef,
  columnNumber = 1,
  wrapperConfig,
  roleVariables = { admin: false },
  isEditActiveField = false,
  policies,
}) => {
  const md = 24 / columnNumber;

  const navigate = useNavigate();
  const { id = "" } = useParams();

  const [deleteUser] = useMutation<
    Types.DeleteUserMutation,
    Types.DeleteUserMutationVariables
  >(
    isAdminService() ? operations.deleteAdminUser : operations.deleteClientUser
  );

  const handleDelete = () => {
    C.Modal.confirm({
      title: "Confirm",
      content: "Are you sure you want to delete this user?",
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        deleteUser({
          variables: { id },
          onCompleted: () => {
            C.message.success("User have been deleted");
            navigate("/users");
          },
        });
      },
    });
  };

  return (
    <C.Formik
      onSubmit={onSubmit}
      innerRef={innerRef}
      validationSchema={consts.validationSchema}
      initialValues={initialValues}
    >
      {wrapperConfig ? (
        <C.Form layout="vertical">
          <C.Content.Item
            title={wrapperConfig.title}
            extra={isEditActiveField && <L.Active md={md} />}
          >
            <C.Row gutter={24}>
              <L.FirstName md={md} />
              <L.LastName md={md} />
              <L.PhoneNumber md={md} />
              <L.Email md={md} />
              <L.Role md={md} variables={roleVariables} />
              {!isAdminService() && <L.RegionLocation md={md} />}
            </C.Row>
          </C.Content.Item>
          <C.Form.Controls>
            {policies?.delete && (
              <C.Button danger icon={<DeleteOutlined />} onClick={handleDelete}>
                Delete
              </C.Button>
            )}
          </C.Form.Controls>
        </C.Form>
      ) : (
        <C.Form layout="vertical">
          <C.Row gutter={24}>
            <L.FirstName md={md} />
            <L.LastName md={md} />
            <L.PhoneNumber md={md} />
            <L.Email md={md} />
            <L.Role md={md} variables={roleVariables} />
            <L.RegionLocation md={md} variables={{ clientId: id }} />
            {isEditActiveField && <L.Active md={md} />}
          </C.Row>
        </C.Form>
      )}
    </C.Formik>
  );
};

export default UserForm;
