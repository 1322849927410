import * as React from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "components";

interface EditWidgets {
  onClick: () => void;
}

const EditWidgets: React.FC<EditWidgets> = ({ onClick }) => {
  return (
    <Button onClick={onClick} type="primary" icon={<EditOutlined />}>
      Edit Widgets
    </Button>
  );
};

export default EditWidgets;
