import { isEmpty, omit } from "lodash-es";
import { Filter } from "types";
import { FormValues } from "./types";

const prepareRegionLocation = (
  name: string,
  value: string | null,
  filter: Filter
): Filter => {
  const { equal, ...filtersWithoutEqual } = filter;
  const equalWithoutValue = omit(equal, name) || {};

  const newFilter: Filter = {
    ...filtersWithoutEqual,
  };

  // exclude region for case when "All" selected
  // and exclude equal when is empty
  if (!isEmpty(equalWithoutValue) || value) {
    newFilter.equal = {
      ...equalWithoutValue,
      ...(value ? { [name]: value } : {}),
    };
  }

  return newFilter;
};

export const prepareFilter = (values: FormValues, filter: Filter): Filter => {
  return Object.entries(values).reduce<Filter>((newFilter, [name, value]) => {
    if (name === "regionId" || name === "locationId")
      return prepareRegionLocation(name, value, newFilter);
    return newFilter;
  }, filter);
};
