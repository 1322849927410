import * as React from "react";
import { message } from "antd";
import { To } from "react-router-dom";
import { useMutation, useNavigate } from "hooks";
import { MutationObject, CreateConfig } from "types";
import { onError } from "utils";
import { CreateContext } from "./duck";

interface CreateProps<TData, TVariables> {
  mutation: MutationObject<TVariables>;
  children:
    | React.ReactNode
    | ((props: CreateConfig<TData, TVariables>) => React.ReactNode);
  successPath?: To | number | ((data: TData) => To | number) | null;
  successMessage?: ((data: TData) => string) | string;
}

function Create<TData, TVariables>({
  mutation,
  children,
  successPath = -1,
  successMessage = "Successfully created",
}: CreateProps<TData, TVariables>) {
  const navigate = useNavigate();

  const [createRecord] = useMutation<TData>(mutation.operation, {
    onCompleted: (response) => {
      if (successPath) {
        navigate(
          typeof successPath === "function"
            ? successPath(response)
            : successPath
        );
      }
      message.success(
        typeof successMessage === "function"
          ? successMessage(response)
          : successMessage
      );
    },
    onError,
    ...mutation.options,
  });

  const onSubmit = async (variables: TVariables) => {
    return createRecord({
      variables: {
        ...mutation.variables,
        ...variables,
      },
    });
  };

  const config = { onSubmit };

  return (
    // TODO: Fix TVariables collision to remove this cast.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <CreateContext.Provider value={config}>
      {typeof children === "function" ? children(config) : children}
    </CreateContext.Provider>
  );
}

export default Create;
