import * as React from "react";
import LOGOS from "assets/logos";
import { Link, Space, Typography } from "components";
import { PHONE } from "consts";
import { cx, isAdminService } from "utils";
import { Confirm, Credentials, CountryCodeSelect } from "./components";
import { Types } from "./duck";
import styles from "./Login.module.css";

const Login: React.FC = () => {
  const [countryIsoCode, setCountryIsoCode] = React.useState<string>(
    PHONE.defaultCountry.isoCode
  );

  const [step, setStep] = React.useState<Types.LoginData>({
    step: "credentials",
    data: {
      phoneNumber: "",
      email: "",
    },
  });

  return (
    <div
      className={cx(styles.wrapper, isAdminService() && styles.adminWrapper)}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          {isAdminService() ? (
            <img src={LOGOS.lightHorizontal} alt="Logo" />
          ) : (
            <img src={LOGOS.darkHorizontal} alt="Logo" />
          )}

          {isAdminService() && (
            <Typography.Text className={styles.subtitle}>
              Admin Part
            </Typography.Text>
          )}

          {step.step === "credentials" && (
            <CountryCodeSelect
              value={countryIsoCode}
              onChange={setCountryIsoCode}
            />
          )}
        </div>

        <div className={styles.content}>
          {step.step === "credentials" && (
            <Credentials
              countryCode={PHONE.countries[countryIsoCode].code}
              onSuccess={(data) => setStep({ step: "confirm", data })}
            />
          )}
          {step.step === "confirm" && (
            <Confirm
              {...step.data}
              onReset={() =>
                setStep({
                  step: "credentials",
                  data: { phoneNumber: "", email: "" },
                })
              }
            />
          )}
        </div>

        <div className={styles.footer}>
          <Space>
            <Typography>Need help?</Typography>
            <Link to="/support">Contact support</Link>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default Login;
