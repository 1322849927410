import * as React from "react";
import * as C from "components";
import { useList } from "hooks";
import { Types } from "../../duck";
import { utils } from "./duck";

const Services: React.FC = () => {
  const { data, loading, pagination, onChange, order } =
    useList<Types.GetDashboardServicesQuery>();

  return (
    <C.Table
      dataSource={data?.dashboardServices.nodes.filter(
        // TODO: filter in query
        // disabled is for assetDisabled
        (service) => !service.disabled
      )}
      pagination={pagination}
      loading={loading}
      onChange={onChange}
      order={order}
      rowKey={(record) => record.woId}
    >
      <C.Table.IDColumn
        dataIndex="identifier"
        title="Vehicle Name"
        width={120}
        render={(identifier, record: Types.Service) => (
          <C.Link to={`/assets/${record.assetId}`}>{identifier}</C.Link>
        )}
        sorter
      />

      <C.Table.Column dataIndex="make" title="Make" sorter />

      <C.Table.Column dataIndex="model" title="Model" sorter />

      <C.Table.Column dataIndex="category" title="Category" sorter />

      <C.Table.Column dataIndex="geolocation" title="Location" sorter />

      <C.Table.Column dataIndex="site" title="Site" sorter />

      <C.Table.Column
        dataIndex="deadline"
        title="Days Till Due"
        align="center"
        render={(deadline) => {
          return (
            <C.Due
              value={utils.getDaysTillDue(deadline)}
              warningAt={Infinity}
            />
          );
        }}
        sorter
      />

      <C.Table.Column
        dataIndex="wo"
        title="WO#"
        render={(wo, { woId }: Types.Maintenance) => {
          if (wo) return <C.Link to={`/work-order/${woId}/edit`}>{wo}</C.Link>;
        }}
        sorter
      />

      <C.Table.Column
        width={110}
        dataIndex="status"
        title="WO Status"
        render={(status, record: Types.Service) => {
          if (status)
            return <C.WorkOrderStatusSelect id={record.woId} status={status} />;
        }}
        sorter
      />
    </C.Table>
  );
};

export default Services;
