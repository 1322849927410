import * as React from "react";
import * as C from "components";
import { cx } from "utils";
import { WidgetName } from "../../duck/types";
import styles from "./Widget.module.css";

type WidgetProps =
  | {
      title: string;
      extra?: React.ReactNode;
      loading?: boolean; // Todo remove "?" after change last widget
      preview?: never;
      name?: never;
    }
  | {
      title: string;
      extra?: never;
      loading?: boolean; // Todo remove "?" after change last widget
      preview: boolean;
      name: WidgetName;
    };

const Widget: React.FC<WidgetProps> = ({
  title,
  extra,
  loading,
  children,
  preview,
  name,
}) => {
  return (
    <div className={cx(styles.wrapper, { [styles.preview]: preview })}>
      {loading ? (
        <C.Content.Loading />
      ) : (
        <>
          <div className={styles.header}>
            <C.Typography.Text strong ellipsis>
              {title}
            </C.Typography.Text>

            <div>
              {preview && name ? (
                <C.Form.Item name={name}>
                  <C.Form.Checkbox name={name}>
                    <C.Typography>Select</C.Typography>
                  </C.Form.Checkbox>
                </C.Form.Item>
              ) : (
                extra
              )}
            </div>
          </div>
          <div className={styles.content}>{children}</div>
        </>
      )}
    </div>
  );
};

export default Widget;
