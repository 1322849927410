import { TablePaginationConfig } from "antd/lib/table";
import { Pagination, QueryParam } from "types";
import { parsePagination } from "utils";

export const DEFAULT_PAGINATION: Pagination = {
  number: 1,
  size: 50,
};

export const DEFAULT_PAGINATION_CONFIG: TablePaginationConfig = {
  ...parsePagination(DEFAULT_PAGINATION),
  pageSizeOptions: ["50", "100", "150"],
  showSizeChanger: true,
  size: "default",
} as TablePaginationConfig;

export const paramsCache = new Map<string, QueryParam>();
