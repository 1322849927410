import * as React from "react";
import { Form } from "antd";
import { FormItemProps as $FormItemProps } from "antd/lib/form/FormItem";
import { Field, FieldProps, getIn, FieldConfig } from "formik";

export type FormItemProps = {
  // for array touched fix
  isArray?: boolean;
  showValidateSuccess?: boolean;
  showInitialErrorAfterTouched?: boolean;
  children: React.ReactNode;
} & { name: string } & $FormItemProps &
  Pick<FieldConfig, "validate">;

export const FormItem = ({
  name,
  showValidateSuccess,
  showInitialErrorAfterTouched = false,
  children,
  validate,
  isArray,
  ...restProps
}: FormItemProps) => (
  <Field name={name} validate={validate}>
    {({
      form: { errors = {}, touched = {}, initialErrors = {} },
    }: FieldProps) => {
      const error = getIn(errors, name, undefined);
      const initialError = getIn(initialErrors, name, undefined);
      let isTouched = getIn(touched, name, false) as boolean | boolean[];
      if (!isArray) {
        if (Array.isArray(isTouched)) {
          isTouched = isTouched.reduce((acc, value) => acc || value, false);
        }
      }
      const hasError = error !== undefined && isTouched;
      const hasInitialError = initialError !== undefined;
      const isValid = !error && isTouched;
      const showHelp =
        hasError ||
        (hasInitialError && (!isTouched || showInitialErrorAfterTouched));

      return (
        <Form.Item
          validateStatus={
            // eslint-disable-next-line no-nested-ternary
            hasError || (hasInitialError && !isTouched)
              ? "error"
              : isValid && showValidateSuccess
              ? "success"
              : undefined
          }
          hasFeedback={Boolean(isValid)}
          help={
            showHelp && (
              <>
                {hasError && <li>{error}</li>}
                {hasInitialError &&
                  (!isTouched || showInitialErrorAfterTouched) && (
                    <li>{initialError}</li>
                  )}
              </>
            )
          }
          {...restProps}
        >
          {children}
        </Form.Item>
      );
    }}
  </Field>
);

export default FormItem;
