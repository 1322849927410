import * as React from "react";
import { Modal, Image, Typography } from "components";

interface PreviewModalProps {
  title: string | undefined;
  visible: boolean;
  onCancel: () => void;
  imageSrc: string | undefined;
}

const PreviewModal: React.FC<PreviewModalProps> = ({
  title,
  visible,
  onCancel,
  imageSrc,
}) => {
  const srcExtension = imageSrc?.split(".").pop();

  return (
    <Modal
      footer={null}
      destroyOnClose
      title={title}
      visible={visible}
      onCancel={onCancel}
    >
      {srcExtension === "tiff" ? (
        <Typography.Link
          key={imageSrc}
          href={imageSrc}
          target="_blank"
          rel="noreferrer"
          download
        >
          {title}
        </Typography.Link>
      ) : (
        <Image
          src={imageSrc}
          alt={title}
          style={{ width: "100%" }}
          preview={false}
        />
      )}
    </Modal>
  );
};

export default PreviewModal;
