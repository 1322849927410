import * as React from "react";
import { Select as AntSelect } from "antd";
import { useQuery } from "hooks";
import { Types } from "./duck";

function QuerySelect<
  TOption extends Record<string, any>,
  TVariables extends Record<string, any>
>({
  optionValue,
  optionLabel,
  query,
  ...props
}: Types.QuerySelectProps<TOption, TVariables>): React.ReactElement {
  const { data, loading } = useQuery<
    Record<string, { nodes: TOption[] }> | Record<string, TOption[]>
  >(query.operation, {
    variables: query.variables,
    ...query.options,
  });

  const queryData = data?.[query.key];

  const options =
    queryData && "nodes" in queryData ? queryData.nodes : queryData;

  return (
    <AntSelect
      options={options?.map((opt) => ({
        label: opt[optionLabel],
        value: opt[optionValue],
        key: opt[optionValue],
      }))}
      loading={loading}
      {...props}
    />
  );
}

export default QuerySelect;
