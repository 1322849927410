import * as React from "react";
import { useMutation } from "@apollo/client";
import { isAdminService, onError } from "utils";
import { AuthContext, operations, Types } from "./duck";

const AuthProvider: React.FC = ({ children }) => {
  const [auth, setAuth] = React.useState<Types.AuthState>({ user: null });
  const [isAuthenticating, setAuthenticating] = React.useState(true);

  const [signOutMutation] = useMutation<
    Types.SignOutMutation,
    Types.SignOutMutationVariables
  >(
    isAdminService()
      ? operations.signOutAdminUser
      : operations.signOutClientUser,
    {
      onError,
    }
  );

  const removeAuthData = React.useCallback(() => {
    setAuth({ ...auth, user: null });
    localStorage.removeItem("token");
  }, [auth]);

  const signOut = React.useCallback(() => {
    if (localStorage.getItem("token")) {
      signOutMutation().finally(removeAuthData);
    } else {
      removeAuthData();
    }
  }, [signOutMutation, removeAuthData]);

  const signIn = React.useCallback(
    (user: Types.User, token: string) => {
      setAuth({ ...auth, user });
      localStorage.setItem("token", token);
    },
    [auth]
  );

  const [signInCheck] = useMutation<Types.SignInCheckMutation>(
    isAdminService()
      ? operations.signInCheckAdmin
      : operations.signInCheckClient,
    {
      onCompleted(data) {
        const user =
          "signinCheckAdminUser" in data
            ? data.signinCheckAdminUser.adminUser
            : data.signinCheckClientUser.user;

        return signIn(user, localStorage.getItem("token") as string);
      },
      onError: signOut,
    }
  );

  React.useEffect(() => {
    const receivedFromURLToken = new URLSearchParams(
      window.location.search
    ).get("token");
    if (receivedFromURLToken)
      localStorage.setItem("token", receivedFromURLToken);
  }, []);

  React.useEffect(() => {
    signInCheck().finally(() => setAuthenticating(false));
  }, [signInCheck]);

  const isLoggedIn = !!auth.user;

  return (
    <AuthContext.Provider
      value={[
        {
          ...auth,
          isAuthenticating,
          isLoggedIn,
          signOut,
          signIn,
        },
        setAuth,
      ]}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
