import * as React from "react";
import * as C from "components";
import { INPUT_MAX_LENGTH } from "consts";
import { FormItemProps } from "../../duck/types";

const FirstName: React.FC<FormItemProps> = ({ md }) => {
  return (
    <C.Col span={24} md={md}>
      <C.Form.Item name="firstName" label="First Name" required>
        <C.Form.Input
          name="firstName"
          placeholder="Enter First Name"
          maxLength={INPUT_MAX_LENGTH.L}
        />
      </C.Form.Item>
    </C.Col>
  );
};

export default FirstName;
