import * as React from "react";
import { Man, Woman, ConeSmall, Cone } from "./assets";
import styles from "./Img404.module.css";

const Img404 = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <img src={Woman} alt="" />
      </div>

      <div className={styles.right}>
        <img src={Man} alt="" />
        <img className={styles.coneSmall} src={ConeSmall} alt="" />
        <img className={styles.cone} src={Cone} alt="" />
      </div>
    </div>
  );
};

export default Img404;
