import * as React from "react";
import { isEmpty, isEqual } from "lodash-es";
import { useQuery, useQueryParam } from "hooks";
import * as Types from "types";
import * as utils from "utils";
import { consts, ListContext, utils as listUtils } from "./duck";

interface ListProps<TData, TVariables = Types.OperationVariables> {
  query: Types.QueryObject<TVariables> & { key: string };
  defaultPagination?: Types.Pagination;
  defaultSort?: Types.Sort;
  defaultOrder?: Types.Order;
  defaultFilter?: Types.Filter;
  children:
    | React.ReactNode
    | ((props: Types.ListConfig<TData, TVariables>) => React.ReactNode);
}

interface ListData {
  [key: string]: {
    pagination: Pick<Types.Page, "totalCount" | "pagesCount">;
    policies?: Types.Policy[];
  };
}

function List<TData extends ListData, TVariables>({
  query,
  defaultPagination = consts.DEFAULT_PAGINATION,
  defaultSort = [], // use for initial table sort for List without sorter
  defaultOrder = {},
  defaultFilter = {},
  children,
}: ListProps<TData, TVariables>) {
  const cachedParams = consts.paramsCache.get(query.key);
  const [params = {}, setQueryParam] = useQueryParam<Types.QueryParam>(
    query.key
  );

  const { pagination, filter, search, order } = params;

  const variables = pagination
    ? listUtils.prepareVariables(
        pagination,
        defaultSort,
        filter,
        search,
        order,
        query.variables
      )
    : undefined;
  const queryResult = useQuery<TData, TVariables>(query.operation, {
    skip: !variables,
    variables,
    onCompleted: (response) => {
      query.options?.onCompleted?.(response);
    },
    onError: utils.onError,
  });

  const { data, previousData } = queryResult;
  const actualData = data || previousData;
  const dataByKey = actualData?.[query.key];
  const total = dataByKey?.pagination.totalCount;

  React.useEffect(() => {
    if (pagination) {
      const { number } = pagination;

      if (number < 1) {
        setQueryParam({ ...params, pagination: { ...pagination, number: 1 } });
      }

      if (total && number > total) {
        setQueryParam({
          ...params,
          pagination: { ...pagination, number: total },
        });
      }
    }
  }, [pagination, params, setQueryParam, total]);

  React.useEffect(() => {
    const isOrderToSetDefault =
      !order || (isEmpty(order) && !isEqual(order, defaultOrder));

    if (isOrderToSetDefault) {
      setQueryParam({
        ...params,
        ...(isOrderToSetDefault ? { order: defaultOrder } : {}),
      });
    }
  }, [order, defaultOrder, setQueryParam, params]);

  React.useEffect(() => {
    /** Setting initial query params:
     * if queryParams exist - just cache them
     * else set queryParams from cache or default
     **/
    if (isEmpty(params)) {
      setQueryParam({
        pagination: cachedParams?.pagination || defaultPagination,
        filter: cachedParams?.filter || defaultFilter,
        order: cachedParams?.order || defaultOrder,
        search: cachedParams?.search,
      });
    } else {
      consts.paramsCache.set(query.key, params);
    }
  }, [
    defaultFilter,
    defaultOrder,
    defaultPagination,
    cachedParams,
    params,
    query.key,
    setQueryParam,
  ]);

  const config: Types.ListConfig<TData, TVariables> = {
    ...queryResult,
    data: actualData,

    pagination: {
      ...consts.DEFAULT_PAGINATION_CONFIG,
      ...(pagination ? utils.parsePagination(pagination) : undefined),
      total,
    },
    // sort: sort ? utils.parseSort(sort) : undefined,
    order: order ? utils.parseOrder(order) : undefined,

    filter,
    setFilter: (newFilter: Types.Filter) =>
      setQueryParam({ ...params, filter: newFilter }),

    search,
    setSearch: (newSearch: Types.Search) =>
      setQueryParam({ ...params, search: newSearch }),

    query: {
      ...query,
      variables,
    },

    paramsCache: consts.paramsCache,

    policies: utils.mapPolicies(actualData?.[query.key].policies),

    // The function for the Table component
    onChange: ({ current, pageSize }, newFilter, newSort) => {
      const newParams = { ...params };

      if (current && pageSize) {
        newParams.pagination = utils.preparePagination({ current, pageSize });
      }

      if (Object.keys(newSort).length || Array.isArray(newSort)) {
        const prepared = utils.prepareSort(newSort);
        newParams.order = prepared.order;
      }

      setQueryParam(newParams);
    },
  };
  return (
    <ListContext.Provider value={config}>
      {typeof children === "function" ? children(config) : children}
    </ListContext.Provider>
  );
}

export default List;
