import { utils } from "../../../../../duck";
import { Region } from "./types";

export const generateTreeData = (regions: Region[]) => [
  {
    id: "all",
    value: "all",
    title: "All",
    children: regions.map((region) => {
      return {
        id: region.id,
        value: region.id,
        title: region.name,
        children: region.locations.map((location) => {
          return {
            id: `${region.id}-${location.id}`,
            title: location.name,
            value: `${region.id}-${location.id}`,
          };
        }),
      };
    }),
  },
];

export const isAllRegions = (checkedValue: string[], regions: Region[]) => {
  const allRegionCount = regions?.reduce(
    (acc, cur) => acc + 1 + (cur.locations ? cur.locations.length : 0),
    0
  );

  const preparedRegionsLocations =
    utils.sliceRegionsAndLocationsIds(checkedValue);

  const checkedCount =
    preparedRegionsLocations.regionIds.length +
    preparedRegionsLocations.locationIds.length;

  if (allRegionCount === 0 && checkedCount === 0) {
    return false;
  }

  return checkedCount >= allRegionCount;
};

export const getPlacesId = (regions: Region[]) =>
  regions.reduce(
    (acc: string[], cur) => [
      ...acc,
      ...utils.getIds(cur.id, cur.locations, "id"),
    ],
    ["all"]
  );
