import * as React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Button, Menu } from "components";
import { useMutation } from "hooks";
import { WorkOrderStatusEnum } from "types";
import { operations, Types, consts } from "./duck";
import styles from "./StatusSelect.module.css";

interface StatusSelectProps {
  status: WorkOrderStatusEnum;
  id: string;
}

const StatusSelect: React.FC<StatusSelectProps> = ({ status, id }) => {
  const [currentStatus, setCurrentStatus] = React.useState(status);
  const [updateWorkOrderStatus] = useMutation<
    Types.UpdateWorkOrderStatusMutation,
    Types.UpdateWorkOrderStatusMutationVariables
  >(operations.updateWorkOrderStatus);

  const onChange = (newStatus: WorkOrderStatusEnum) => {
    setCurrentStatus(newStatus);

    updateWorkOrderStatus({ variables: { id, status: newStatus } }).then(
      ({ errors }) => {
        if (errors) setCurrentStatus(currentStatus); // reset value
      }
    );
  };

  return (
    <Dropdown
      overlay={
        <Menu
          style={{ minWidth: 120 }}
          selectedKeys={[currentStatus]}
          className={styles.menuWrapper}
        >
          {consts.statusOptions.map((option) => (
            <Menu.Item
              key={option.value}
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </Menu.Item>
          ))}
        </Menu>
      }
      placement="bottomRight"
      trigger={["click"]}
    >
      <Button type="link" className={styles.button}>
        {consts.statusOptions.find((o) => o.value === currentStatus)?.label}

        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default StatusSelect;
