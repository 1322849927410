import * as React from "react";
import { FieldProps } from "formik";
import { FormikFieldProps } from "formik-antd/lib/FieldProps";
import { Field } from "components";
import Select, { SelectProps } from "../../../select";

type FormSelectProps<TOption, TVariables> = SelectProps<TOption, TVariables> &
  FormikFieldProps;

function FormSelect<TOption, TVariables>({
  name,
  onChange,
  onBlur,
  ...restProps
}: FormSelectProps<TOption, TVariables>): React.ReactElement {
  return (
    <Field name={name}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
      }: FieldProps) => {
        return (
          <Select
            onChange={(newValue, option) => {
              setFieldValue(name, newValue);
              onChange?.(newValue, option);
            }}
            onBlur={(newValue) => {
              setFieldTouched(name);
              onBlur?.(newValue);
            }}
            showSearch
            optionFilterProp="label"
            value={value === "" || value === null ? undefined : value}
            {...restProps}
          />
        );
      }}
    </Field>
  );
}

FormSelect.Option = Select.Option;

export default FormSelect;
