import * as React from "react";
import { FormikProps } from "formik";
import { Form, Formik } from "components";
import { Group, Name, Unit } from "./components";
import { Types, consts } from "./duck";

interface TaskFormProps {
  innerRef?: React.Ref<FormikProps<Types.FormValues>>;
  initialValues?: Types.FormValues;
  onSubmit: (values: Types.FormValues) => Promise<any>;
  children?: React.ReactNode;
}

const TaskForm = ({
  innerRef,
  initialValues = { name: "" },
  onSubmit,
  children,
}: TaskFormProps): React.ReactElement => {
  return (
    <Formik
      innerRef={innerRef}
      onSubmit={onSubmit}
      validationSchema={consts.validationSchema}
      initialValues={initialValues}
    >
      <Form layout="vertical">
        {children || (
          <>
            <Name />
            <Unit />
            <Group />
          </>
        )}
      </Form>
    </Formik>
  );
};

TaskForm.Group = Group;
TaskForm.Name = Name;
TaskForm.Unit = Unit;

export default TaskForm;
