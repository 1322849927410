import * as React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "lodash-es";
import { Input } from "components";
import { useList } from "hooks";
import { Search as SearchType } from "types";
import styles from "./Search.module.css";

interface SearchProps {
  onChange?: (value: string) => void;
}

const Search: React.FC<SearchProps> = ({ onChange }) => {
  const mountRef = React.useRef(true);
  const { search, setSearch } = useList<SearchType>();
  const [value, setValue] = React.useState(search);

  const handleChange = React.useMemo(
    () =>
      debounce((v) => {
        if (mountRef.current) setSearch(v);
      }, 1000),
    [setSearch]
  );

  React.useEffect(() => {
    setValue(search);
  }, [search]);

  React.useEffect(
    () => () => {
      mountRef.current = false;
    },
    []
  );

  return (
    <Input
      value={value || ""}
      placeholder="Search"
      prefix={<SearchOutlined className={styles.searchIcon} />}
      onChange={(e) => {
        setValue(e.currentTarget.value);
        handleChange(e.currentTarget.value);
        onChange?.(e.currentTarget.value);
      }}
    />
  );
};

export default Search;
