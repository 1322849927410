import * as React from "react";
import Widget from "../../../widget";
import chartPreview from "./assets/preview.svg";

const Preview: React.FC = () => {
  return (
    <Widget title="Work Orders by Status" preview name="workOrderByStatus">
      <img src={chartPreview} alt="" />
    </Widget>
  );
};

export default Preview;
