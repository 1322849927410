import * as React from "react";
import * as C from "components";
import { useQuery } from "hooks";
import { WidgetFC } from "../../duck/types";
import Widget from "../widget";
import { Preview } from "./components";
import { operations, Types } from "./duck";

const columns: C.TableColumnsType<Types.AssetSchedule> = [
  {
    dataIndex: "asset",
    render: ({ id, identifier }: Types.AssetSchedule["asset"]) => (
      <C.Link to={`/assets/${id}`}>{identifier}</C.Link>
    ),
    ellipsis: {
      showTitle: true,
    },
  },
  {
    title: "Miles",
    dataIndex: "milesTillDue",
    align: "center",
    render: (miles: number, { schedule }: Types.AssetSchedule) => (
      <C.Due warningAt={schedule.uwtMileage} value={miles} />
    ),
  },
  {
    title: "Hours",
    dataIndex: "hoursTillDue",
    align: "center",
    render: (hours: number, { schedule }: Types.AssetSchedule) => (
      <C.Due warningAt={schedule.uwtEngineHour} value={hours} />
    ),
  },
  {
    title: "Days",
    dataIndex: "daysTillDue",
    align: "center",
    render: (days: number, { schedule }: Types.AssetSchedule) => (
      <C.Due warningAt={schedule.uwtTime} value={days} />
    ),
  },
];

const PastDue: WidgetFC = () => {
  const { data, loading } = useQuery<
    Types.GetAssetSchedulesQuery,
    Types.GetAssetSchedulesQueryVariables
  >(operations.getAssetSchedules);

  return (
    <Widget title="Past Due" loading={loading}>
      <C.Table
        columns={columns}
        dataSource={data?.assetSchedules.nodes}
        pagination={false}
        bordered={false}
        size="small"
      />
    </Widget>
  );
};

PastDue.Preview = Preview;

export default PastDue;
