import * as React from "react";
import { Navigate, Route, Routes } from "components";
import { useLocation } from "hooks";
import * as P from "pages";

const NotAuthRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="login" element={<P.Login />} />
      <Route path="support" element={<P.Support />} />
      <Route
        path="*"
        element={<Navigate to="login" state={{ from: location }} />}
      />
    </Routes>
  );
};

export default NotAuthRoutes;
