import * as React from "react";
import { Form } from "components";
import { useField, useFormikContext } from "hooks";

const allOption = {
  label: "All",
  value: null,
};

interface SelectProps {
  name: string;
  options: {
    label: string;
    value: string;
  }[];
}

const Select: React.FC<SelectProps> = ({ name, options }) => {
  const { submitForm } = useFormikContext();
  const [{ value }, , { setValue }] = useField(name);
  // eslint-disable-next-line
  const handleChange = (newValue: string | null) => {
    setValue(newValue);
    submitForm();
  };
  const singleOption = options.length === 1 ? options[0] : null;
  const defaultOption = singleOption || allOption;

  React.useEffect(() => {
    // dangerously update state to set filter to default value, when chosen nonexistent option
    if (
      !options.find((o) => o.value === value) &&
      value !== defaultOption.value
    ) {
      handleChange(defaultOption.value);
    }
  }, [options, value, handleChange, defaultOption]);

  return (
    <Form.Select
      name={name}
      dropdownStyle={{ maxWidth: "200px" }}
      bordered={false}
      defaultValue={defaultOption.value}
      dropdownMatchSelectWidth={false}
      options={singleOption ? [defaultOption] : [defaultOption, ...options]}
      size="small"
      // TODO: Remove this one and fix Select problem inside PageHeader via styles.
      // eslint-disable-next-line
      //@ts-ignore
      getPopupContainer={() => document.getElementById("root")}
      onChange={(newValue) => {
        handleChange(newValue);
      }}
      value={value}
    />
  );
};

export default Select;
