import * as React from "react";
import { MenuItemProps } from "antd";
import { Menu, message, Modal } from "components";
import { useList, useMutation } from "hooks";
import { MutationObject } from "types";
import { onError } from "utils";

interface MutationProps<TVariables>
  extends Omit<MenuItemProps, "children" | "onClick"> {
  mutation: MutationObject<TVariables>;
  successMessage?: React.ReactNode;
  confirmTitle?: React.ReactNode;
  confirmMessage?: React.ReactNode;
  cancelText?: React.ReactNode;
  okText?: React.ReactNode;
  children: React.ReactNode;
}

const Mutation = <TData, TVariables>({
  mutation,
  successMessage = "Deleted",
  confirmTitle = "Confirm",
  confirmMessage = "",
  cancelText = "No",
  okText = "Yes",
  children,
  ...restProps
}: MutationProps<TVariables>): React.ReactElement => {
  const { query } = useList();

  const [onConfirm] = useMutation<TData, TVariables>(mutation.operation, {
    variables: mutation.variables,
    refetchQueries: [{ query: query.operation, variables: query.variables }],
    onCompleted: () => message.success(successMessage),
    onError,
  });

  const handleConfirm = () => {
    Modal.confirm({
      title: confirmTitle,
      content: confirmMessage,
      onOk: onConfirm,
      cancelText,
      okText,
    });
  };

  return (
    <Menu.Item {...restProps} onClick={handleConfirm}>
      {children}
    </Menu.Item>
  );
};

export default Mutation;
