/* eslint-disable */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
  Upload: any;
};

export type Auth_Provider_Credentials = {
  email?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  passcode?: Maybe<Scalars["String"]>;
};

export type AdminUser = BasePolicy &
  Record & {
    active: Scalars["Boolean"];
    allRegions: Scalars["Boolean"];
    createdAt: Scalars["ISO8601DateTime"];
    email: Scalars["String"];
    firstName: Scalars["String"];
    id: Scalars["ID"];
    lastName: Scalars["String"];
    locations: Array<Location>;
    phoneNumber: Scalars["String"];
    policies: Array<Policy>;
    regions: Array<Region>;
    role: Role;
    updatedAt: Scalars["ISO8601DateTime"];
    widgetSettings?: Maybe<Scalars["JSON"]>;
  };

export type AdminUserPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type AllTask = {
  actualParameter?: Maybe<Scalars["String"]>;
  selected?: Maybe<Scalars["Boolean"]>;
  task: Task;
};

export type Asset = BasePolicy &
  Record & {
    assetClass?: Maybe<Scalars["String"]>;
    assetSchedules: Array<AssetSchedule>;
    assetSpecs: Array<AssetSpec>;
    category: Category;
    createdAt: Scalars["ISO8601DateTime"];
    currentDays?: Maybe<Scalars["Float"]>;
    currentHourMeter?: Maybe<Scalars["Float"]>;
    currentOdo?: Maybe<Scalars["Float"]>;
    dieselExhaustFluidLevel?: Maybe<Scalars["Float"]>;
    disabled: Scalars["Boolean"];
    fuelType?: Maybe<Scalars["String"]>;
    geolocation?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    identifier: Scalars["String"];
    images: Array<Attachment>;
    invoices: Array<Attachment>;
    kind: KindEnum;
    licensePlate?: Maybe<Scalars["String"]>;
    location?: Maybe<Location>;
    mainImageId?: Maybe<Scalars["ID"]>;
    make: Make;
    model: Model;
    notes?: Maybe<Scalars["String"]>;
    policies: Array<Policy>;
    redDieselExhaustFluidLevel: Scalars["Float"];
    region?: Maybe<Region>;
    registrationDate?: Maybe<Scalars["ISO8601Date"]>;
    schedules: Array<Schedule>;
    services: Array<Service>;
    site?: Maybe<Scalars["String"]>;
    state?: Maybe<Scalars["String"]>;
    status: AssetStatusEnum;
    statusChangeDate: Scalars["ISO8601DateTime"];
    updatedAt: Scalars["ISO8601DateTime"];
    vin?: Maybe<Scalars["String"]>;
    year?: Maybe<Scalars["Int"]>;
  };

export type AssetPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type AssetEqualFilter = {
  disabled?: Maybe<Scalars["Boolean"]>;
  identifier?: Maybe<Scalars["String"]>;
  make?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  regionId?: Maybe<Scalars["ID"]>;
  locationId?: Maybe<Scalars["ID"]>;
};

export type AssetFilter = {
  OR?: Maybe<AssetFilter>;
  equal?: Maybe<AssetEqualFilter>;
  like?: Maybe<AssetLikeFilter>;
  in?: Maybe<AssetInFilter>;
  nin?: Maybe<AssetInFilter>;
};

export type AssetHistory = {
  assetId: Scalars["ID"];
  date: Scalars["ISO8601Date"];
  days?: Maybe<Scalars["Float"]>;
  hours?: Maybe<Scalars["Float"]>;
  odo?: Maybe<Scalars["Float"]>;
};

export type AssetInFilter = {
  disabled?: Maybe<Array<Scalars["Boolean"]>>;
  identifier?: Maybe<Array<Scalars["String"]>>;
  make?: Maybe<Array<Scalars["String"]>>;
  model?: Maybe<Array<Scalars["String"]>>;
  category?: Maybe<Array<Scalars["String"]>>;
  regionId?: Maybe<Array<Scalars["ID"]>>;
  locationId?: Maybe<Array<Scalars["ID"]>>;
};

export type AssetLikeFilter = {
  identifier?: Maybe<Scalars["String"]>;
  make?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
};

export type AssetSchedule = Record & {
  activeWorkOrder?: Maybe<WorkOrder>;
  asset: Asset;
  assetId: Scalars["ID"];
  createdAt: Scalars["ISO8601DateTime"];
  daysTillDue?: Maybe<Scalars["Int"]>;
  deleted: Scalars["Boolean"];
  hoursTillDue?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  milesTillDue?: Maybe<Scalars["Int"]>;
  nextService?: Maybe<Scalars["String"]>;
  rateTillDue?: Maybe<Scalars["Float"]>;
  schedule: Schedule;
  scheduleId: Scalars["ID"];
  updatedAt: Scalars["ISO8601DateTime"];
  workOrders?: Maybe<Array<WorkOrder>>;
};

export type AssetScheduleArrayFields = {
  assetId?: Maybe<Array<Scalars["ID"]>>;
  scheduleId?: Maybe<Array<Scalars["ID"]>>;
  daysTillDue?: Maybe<Array<Scalars["Int"]>>;
  hoursTillDue?: Maybe<Array<Scalars["Int"]>>;
  milesTillDue?: Maybe<Array<Scalars["Int"]>>;
  rateTillDue?: Maybe<Array<Scalars["Int"]>>;
  deleted?: Maybe<Array<Scalars["Boolean"]>>;
  assetDisabled?: Maybe<Array<Scalars["Boolean"]>>;
};

export type AssetScheduleFields = {
  assetId?: Maybe<Scalars["ID"]>;
  scheduleId?: Maybe<Scalars["ID"]>;
  daysTillDue?: Maybe<Scalars["Int"]>;
  hoursTillDue?: Maybe<Scalars["Int"]>;
  milesTillDue?: Maybe<Scalars["Int"]>;
  rateTillDue?: Maybe<Scalars["Int"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
  assetDisabled?: Maybe<Scalars["Boolean"]>;
};

export type AssetScheduleFilter = {
  OR?: Maybe<AssetScheduleFilter>;
  equal?: Maybe<AssetScheduleFields>;
  in?: Maybe<AssetScheduleArrayFields>;
  nin?: Maybe<AssetScheduleArrayFields>;
};

export type AssetScheduleSort = {
  createdAt?: Maybe<DirectionEnum>;
  daysTillDue?: Maybe<DirectionEnum>;
  milesTillDue?: Maybe<DirectionEnum>;
  hoursTillDue?: Maybe<DirectionEnum>;
  rateTillDue?: Maybe<DirectionEnum>;
};

export type AssetSpec = Record & {
  asset: Asset;
  assetId: Scalars["ID"];
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  name: Scalars["String"];
  specId?: Maybe<Scalars["ID"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  value?: Maybe<Scalars["String"]>;
};

export enum AssetStatusEnum {
  Parked = "Parked",
  Driving = "Driving",
  Idling = "Idling",
  Installed = "Installed",
  ParkedWithAlert = "Parked_With_Alert",
  Ping = "Ping",
  Speeding = "Speeding",
  Towing = "Towing",
  Unknown = "Unknown",
  Unplugged = "Unplugged",
  WaitingForData = "Waiting_For_Data",
}

export type Attachment = {
  blobId: Scalars["ID"];
  createdAt: Scalars["ISO8601DateTime"];
  filename: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  path?: Maybe<Scalars["String"]>;
  recordId: Scalars["ID"];
  recordType: Scalars["String"];
  signedBlobId: Scalars["ID"];
  url?: Maybe<Scalars["String"]>;
};

export type BasePolicy = {
  policies: Array<Policy>;
};

export type BasePolicyPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type Category = BasePolicy &
  Record & {
    assets: Array<Asset>;
    createdAt: Scalars["ISO8601DateTime"];
    id: Scalars["ID"];
    name: Scalars["String"];
    policies: Array<Policy>;
    updatedAt: Scalars["ISO8601DateTime"];
  };

export type CategoryPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type CategoryArrayFields = {
  id?: Maybe<Array<Scalars["ID"]>>;
  name?: Maybe<Array<Scalars["String"]>>;
};

export type CategoryFields = {
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type CategoryFilter = {
  OR?: Maybe<CategoryFilter>;
  equal?: Maybe<CategoryFields>;
  like?: Maybe<CategoryFields>;
  in?: Maybe<CategoryArrayFields>;
  nin?: Maybe<CategoryArrayFields>;
};

export type CategorySort = {
  id?: Maybe<DirectionEnum>;
  name?: Maybe<DirectionEnum>;
};

export type Client = BasePolicy &
  Record & {
    active: Scalars["Boolean"];
    city: Scalars["String"];
    companyName: Scalars["String"];
    createdAt: Scalars["ISO8601DateTime"];
    id: Scalars["ID"];
    identifier: Scalars["String"];
    owner?: Maybe<ClientUser>;
    policies: Array<Policy>;
    postTown?: Maybe<Scalars["String"]>;
    state: ClientStateEnum;
    streetAddress: Scalars["String"];
    updatedAt: Scalars["ISO8601DateTime"];
    zip: Scalars["String"];
  };

export type ClientPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export enum ClientStateEnum {
  Alabama = "Alabama",
  Alaska = "Alaska",
  Arizona = "Arizona",
  Arkansas = "Arkansas",
  California = "California",
  Colorado = "Colorado",
  Connecticut = "Connecticut",
  Delaware = "Delaware",
  DistrictOfColumbia = "District_of_Columbia",
  Florida = "Florida",
  Georgia = "Georgia",
  Hawaii = "Hawaii",
  Idaho = "Idaho",
  Illinois = "Illinois",
  Indiana = "Indiana",
  Iowa = "Iowa",
  Kansas = "Kansas",
  Kentucky = "Kentucky",
  Louisiana = "Louisiana",
  Maine = "Maine",
  Maryland = "Maryland",
  Massachusetts = "Massachusetts",
  Michigan = "Michigan",
  Minnesota = "Minnesota",
  Mississippi = "Mississippi",
  Missouri = "Missouri",
  Montana = "Montana",
  Nebraska = "Nebraska",
  Nevada = "Nevada",
  NewHampshire = "New_Hampshire",
  NewJersey = "New_Jersey",
  NewMexico = "New_Mexico",
  NewYork = "New_York",
  NorthCarolina = "North_Carolina",
  NorthDakota = "North_Dakota",
  Ohio = "Ohio",
  Oklahoma = "Oklahoma",
  Oregon = "Oregon",
  Pennsylvania = "Pennsylvania",
  RhodeIsland = "Rhode_Island",
  SouthCarolina = "South_Carolina",
  SouthDakota = "South_Dakota",
  Tennessee = "Tennessee",
  Texas = "Texas",
  Utah = "Utah",
  Vermont = "Vermont",
  Virginia = "Virginia",
  Washington = "Washington",
  WestVirginia = "West_Virginia",
  Wisconsin = "Wisconsin",
  Wyoming = "Wyoming",
  AmericanSamoa = "American_Samoa",
  Guam = "Guam",
  Mp = "MP",
  Pr = "PR",
  Vi = "VI",
  Um = "UM",
}

export type ClientUser = BasePolicy &
  Record & {
    active: Scalars["Boolean"];
    allRegions: Scalars["Boolean"];
    company?: Maybe<Client>;
    createdAt: Scalars["ISO8601DateTime"];
    email: Scalars["String"];
    firstName: Scalars["String"];
    id: Scalars["ID"];
    lastName: Scalars["String"];
    locations: Array<Location>;
    phoneNumber: Scalars["String"];
    policies: Array<Policy>;
    regions: Array<Region>;
    role: Role;
    updatedAt: Scalars["ISO8601DateTime"];
    widgetSettings?: Maybe<Scalars["JSON"]>;
  };

export type ClientUserPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type ClientUsersArrayFields = {
  firstName?: Maybe<Array<Scalars["String"]>>;
  lastName?: Maybe<Array<Scalars["String"]>>;
  email?: Maybe<Array<Scalars["String"]>>;
  phoneNumber?: Maybe<Array<Scalars["String"]>>;
  schedule?: Maybe<Array<Scalars["String"]>>;
  active?: Maybe<Array<Scalars["Boolean"]>>;
  clientId?: Maybe<Array<Scalars["ID"]>>;
  roleId?: Maybe<Array<Scalars["ID"]>>;
};

export type ClientUsersFields = {
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  schedule?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  clientId?: Maybe<Scalars["ID"]>;
  roleId?: Maybe<Scalars["ID"]>;
};

export type ClientUsersFilter = {
  OR?: Maybe<ClientUsersFilter>;
  equal?: Maybe<ClientUsersFields>;
  like?: Maybe<ClientUsersFields>;
  in?: Maybe<ClientUsersArrayFields>;
  nin?: Maybe<ClientUsersArrayFields>;
};

export type ClientUsersSort = {
  firstName?: Maybe<DirectionEnum>;
  lastName?: Maybe<DirectionEnum>;
  email?: Maybe<DirectionEnum>;
  phoneNumber?: Maybe<DirectionEnum>;
  createdAt?: Maybe<DirectionEnum>;
};

export type ClientsIndex = BasePolicy & {
  active: Scalars["Boolean"];
  companyName: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  email?: Maybe<Scalars["String"]>;
  holderName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  identifier: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  policies: Array<Policy>;
  streetAddress: Scalars["String"];
};

export type ClientsIndexPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type ClientsIndexSort = {
  identifier?: Maybe<DirectionEnum>;
  holderName?: Maybe<DirectionEnum>;
  companyName?: Maybe<DirectionEnum>;
  email?: Maybe<DirectionEnum>;
  phoneNumber?: Maybe<DirectionEnum>;
  streetAddress?: Maybe<DirectionEnum>;
  active?: Maybe<DirectionEnum>;
  createdAt?: Maybe<DirectionEnum>;
};

/** Autogenerated return type of CreateDirectApolloUpload */
export type CreateDirectApolloUploadPayload = {
  directUpload: Array<DirectUpload>;
};

/** Autogenerated return type of CreateDirectUpload */
export type CreateDirectUploadPayload = {
  directUpload: DirectUpload;
};

/** Regions location attributes */
export type CreateLocationInput = {
  name: Scalars["String"];
};

/** Makes model attributes */
export type CreateModelInput = {
  name: Scalars["String"];
};

/** Client's owner attributes */
export type CreateOwnerInput = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
  phoneNumber: Scalars["String"];
};

export type CurrentMaintenance = {
  all: Scalars["Int"];
  comingDue: Scalars["Int"];
  pastDue: Scalars["Int"];
};

export type DashboardService = {
  assetId: Scalars["ID"];
  category: Scalars["String"];
  deadline: Scalars["ISO8601DateTime"];
  disabled: Scalars["Boolean"];
  geolocation?: Maybe<Scalars["String"]>;
  identifier: Scalars["String"];
  location?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["ID"]>;
  make: Scalars["String"];
  model: Scalars["String"];
  regionId?: Maybe<Scalars["ID"]>;
  site?: Maybe<Scalars["String"]>;
  status: WorkOrderStatusEnum;
  wo: Scalars["String"];
  woId: Scalars["ID"];
};

export type DashboardServiceArrayFields = {
  regionId?: Maybe<Array<Scalars["ID"]>>;
  locationId?: Maybe<Array<Scalars["ID"]>>;
};

export type DashboardServiceFields = {
  regionId?: Maybe<Scalars["ID"]>;
  locationId?: Maybe<Scalars["ID"]>;
};

export type DashboardServiceFilter = {
  OR?: Maybe<DashboardServiceFilter>;
  equal?: Maybe<DashboardServiceFields>;
  like?: Maybe<DashboardServiceFields>;
  in?: Maybe<DashboardServiceArrayFields>;
  nin?: Maybe<DashboardServiceArrayFields>;
};

export type DashboardServiceSort = {
  identifier?: Maybe<DirectionEnum>;
  make?: Maybe<DirectionEnum>;
  model?: Maybe<DirectionEnum>;
  category?: Maybe<DirectionEnum>;
  geolocation?: Maybe<DirectionEnum>;
  site?: Maybe<DirectionEnum>;
  deadline?: Maybe<DirectionEnum>;
  wo?: Maybe<DirectionEnum>;
  status?: Maybe<DirectionEnum>;
};

/** Represents direct upload credentials */
export type DirectUpload = {
  /** Created blob record ID */
  blobId: Scalars["ID"];
  /** HTTP request headers (JSON-encoded) */
  headers: Scalars["String"];
  /** Created blob record signed ID */
  signedBlobId: Scalars["ID"];
  /** Upload URL */
  url: Scalars["String"];
};

/** File information required to prepare a direct upload */
export type DirectUploadInput = {
  /** Original file name */
  filename: Scalars["String"];
  /** File size (bytes) */
  byteSize: Scalars["Int"];
  /** MD5 file checksum as base64 */
  checksum: Scalars["String"];
  /** File content type */
  contentType: Scalars["String"];
};

export enum DirectionEnum {
  Desc = "DESC",
  Asc = "ASC",
}

export type FileImport = {
  /** Import errors */
  errors?: Maybe<Array<Scalars["String"]>>;
  /** Count not uploaded */
  notUploadedCount: Scalars["Int"];
  /** Total count */
  totalCount: Scalars["Int"];
  /** Count uploaded */
  uploadedCount: Scalars["Int"];
};

/** Autogenerated return type of ImportAsset */
export type ImportAssetPayload = {
  fileImport: FileImport;
};

/** Autogenerated return type of ImportTask */
export type ImportTaskPayload = {
  fileImport: FileImport;
};

export type IndexAsset = {
  category: Scalars["String"];
  id: Scalars["String"];
  identifier: Scalars["String"];
  location?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["ID"]>;
  make: Scalars["String"];
  model: Scalars["String"];
  region?: Maybe<Scalars["String"]>;
  regionId?: Maybe<Scalars["ID"]>;
  schedule?: Maybe<Scalars["String"]>;
};

export type IndexAssetArrayFields = {
  identifier?: Maybe<Array<Scalars["String"]>>;
  make?: Maybe<Array<Scalars["String"]>>;
  model?: Maybe<Array<Scalars["String"]>>;
  category?: Maybe<Array<Scalars["String"]>>;
  schedule?: Maybe<Array<Scalars["String"]>>;
  disabled?: Maybe<Array<Scalars["Boolean"]>>;
  regionId?: Maybe<Array<Scalars["ID"]>>;
  locationId?: Maybe<Array<Scalars["ID"]>>;
};

export type IndexAssetFields = {
  identifier?: Maybe<Scalars["String"]>;
  make?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  schedule?: Maybe<Scalars["String"]>;
  disabled?: Maybe<Scalars["Boolean"]>;
  regionId?: Maybe<Scalars["ID"]>;
  locationId?: Maybe<Scalars["ID"]>;
};

export type IndexAssetFilter = {
  OR?: Maybe<IndexAssetFilter>;
  equal?: Maybe<IndexAssetFields>;
  like?: Maybe<IndexAssetFields>;
  in?: Maybe<IndexAssetArrayFields>;
  nin?: Maybe<IndexAssetArrayFields>;
};

export type IndexAssetSort = {
  identifier?: Maybe<DirectionEnum>;
  make?: Maybe<DirectionEnum>;
  model?: Maybe<DirectionEnum>;
  category?: Maybe<DirectionEnum>;
  schedule?: Maybe<DirectionEnum>;
};

export enum KindEnum {
  OnRoad = "ON_ROAD",
  OffRoad = "OFF_ROAD",
  Trailer = "TRAILER",
}

export type Location = BasePolicy &
  Record & {
    createdAt: Scalars["ISO8601DateTime"];
    id: Scalars["ID"];
    name: Scalars["String"];
    policies: Array<Policy>;
    region: Region;
    updatedAt: Scalars["ISO8601DateTime"];
  };

export type LocationPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type LocationArrayFields = {
  id?: Maybe<Array<Scalars["ID"]>>;
  name?: Maybe<Array<Scalars["String"]>>;
  region?: Maybe<Array<Scalars["String"]>>;
  regionId?: Maybe<Array<Scalars["ID"]>>;
};

export type LocationFields = {
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  regionId?: Maybe<Scalars["ID"]>;
};

export type LocationFilter = {
  OR?: Maybe<LocationFilter>;
  equal?: Maybe<LocationFields>;
  like?: Maybe<LocationFields>;
  in?: Maybe<LocationArrayFields>;
  nin?: Maybe<LocationArrayFields>;
};

export type LocationSort = {
  createdAt?: Maybe<DirectionEnum>;
  id?: Maybe<DirectionEnum>;
  region?: Maybe<DirectionEnum>;
  name?: Maybe<DirectionEnum>;
};

export type Maintenance = BasePolicy & {
  assetId: Scalars["ID"];
  category: Scalars["String"];
  daysTillDue?: Maybe<Scalars["Int"]>;
  hoursTillDue?: Maybe<Scalars["Int"]>;
  identifier: Scalars["String"];
  location?: Maybe<Scalars["String"]>;
  locationId?: Maybe<Scalars["ID"]>;
  make: Scalars["String"];
  milesTillDue?: Maybe<Scalars["Int"]>;
  model: Scalars["String"];
  nextService: Scalars["String"];
  policies: Array<Policy>;
  rateTillDue?: Maybe<Scalars["Float"]>;
  regionId?: Maybe<Scalars["ID"]>;
  schedule: Scalars["String"];
  scheduleId: Scalars["ID"];
  site?: Maybe<Scalars["String"]>;
  status: WorkOrderStatusEnum;
  uwtEngineHour?: Maybe<Scalars["Int"]>;
  uwtMileage?: Maybe<Scalars["Int"]>;
  uwtTime?: Maybe<Scalars["Int"]>;
  wo: Scalars["String"];
  woId: Scalars["ID"];
};

export type MaintenancePoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type MaintenanceArrayFields = {
  regionId?: Maybe<Array<Scalars["ID"]>>;
  locationId?: Maybe<Array<Scalars["ID"]>>;
};

export type MaintenanceFields = {
  regionId?: Maybe<Scalars["ID"]>;
  locationId?: Maybe<Scalars["ID"]>;
};

export type MaintenanceFilter = {
  OR?: Maybe<MaintenanceFilter>;
  equal?: Maybe<MaintenanceFields>;
  like?: Maybe<MaintenanceFields>;
  in?: Maybe<MaintenanceArrayFields>;
  nin?: Maybe<MaintenanceArrayFields>;
};

export type MaintenanceSort = {
  identifier?: Maybe<DirectionEnum>;
  make?: Maybe<DirectionEnum>;
  model?: Maybe<DirectionEnum>;
  nextService?: Maybe<DirectionEnum>;
  schedule?: Maybe<DirectionEnum>;
  category?: Maybe<DirectionEnum>;
  location?: Maybe<DirectionEnum>;
  site?: Maybe<DirectionEnum>;
  milesTillDue?: Maybe<DirectionEnum>;
  hoursTillDue?: Maybe<DirectionEnum>;
  daysTillDue?: Maybe<DirectionEnum>;
  rateTillDue?: Maybe<DirectionEnum>;
  wo?: Maybe<DirectionEnum>;
  status?: Maybe<DirectionEnum>;
};

export type Make = BasePolicy &
  Record & {
    assets: Array<Asset>;
    createdAt: Scalars["ISO8601DateTime"];
    id: Scalars["ID"];
    models: Array<Model>;
    name: Scalars["String"];
    policies: Array<Policy>;
    updatedAt: Scalars["ISO8601DateTime"];
  };

export type MakePoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type Model = BasePolicy &
  Record & {
    createdAt: Scalars["ISO8601DateTime"];
    id: Scalars["ID"];
    make: Make;
    name: Scalars["String"];
    policies: Array<Policy>;
    updatedAt: Scalars["ISO8601DateTime"];
  };

export type ModelPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type ModelArrayFields = {
  id?: Maybe<Array<Scalars["ID"]>>;
  name?: Maybe<Array<Scalars["String"]>>;
  make?: Maybe<Array<Scalars["String"]>>;
};

export type ModelFields = {
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  make?: Maybe<Scalars["String"]>;
  makeId?: Maybe<Scalars["ID"]>;
};

export type ModelFilter = {
  OR?: Maybe<ModelFilter>;
  equal?: Maybe<ModelFields>;
  like?: Maybe<ModelFields>;
  in?: Maybe<ModelArrayFields>;
  nin?: Maybe<ModelArrayFields>;
};

export type ModelSort = {
  createdAt?: Maybe<DirectionEnum>;
  id?: Maybe<DirectionEnum>;
  make?: Maybe<DirectionEnum>;
  name?: Maybe<DirectionEnum>;
};

export type Mutation = {
  attachAssetImage: Asset;
  attachAssetInvoice: Asset;
  attachWorkOrderFile: WorkOrder;
  changeWorkOrderScheduleStep: WorkOrder;
  createAdminUser: AdminUser;
  createAsset: Asset;
  createCategory: Category;
  createClient: Client;
  createClientUser: ClientUser;
  createDirectApolloUpload: CreateDirectApolloUploadPayload;
  createDirectUpload: CreateDirectUploadPayload;
  createLocations: Region;
  createMake: Make;
  createModels: Make;
  createRegion: Region;
  createSchedule: Schedule;
  createScheduleStep: ScheduleStep;
  createService: Service;
  createStep: Step;
  createTask: Task;
  createTaskGroup: TaskGroup;
  deleteAttachment: Attachment;
  destroyAdminUser: AdminUser;
  destroyAsset: Asset;
  destroyCategory: Category;
  destroyClientUser: ClientUser;
  destroyClientUserByAdmin: ClientUser;
  destroyLocation: Location;
  destroyMake: Make;
  destroyModel: Model;
  destroyRegion: Region;
  destroySchedule: Schedule;
  destroyScheduleStep: ScheduleStep;
  destroyStep: Step;
  destroyTaskGroup: TaskGroup;
  importAsset: ImportAssetPayload;
  importTask: ImportTaskPayload;
  inactivateTask: Task;
  loadWorkOrderPdfFile: WorkOrder;
  saveAndCloseWorkOrder: WorkOrder;
  signOutAdminUser: SignOutAdminUserPayload;
  signOutAsClient: SignOutAsClientPayload;
  signOutClientUser: SignOutUserPayload;
  signinAdminUser: SignInAdminUserPayload;
  signinAsClient: SignInAsClientPayload;
  signinCheckAdminUser: SignInCheckAdminUserPayload;
  signinCheckClientUser: SignInCheckUserPayload;
  signinClientUser: SignInUserPayload;
  toggleWorkOrderTasks: WorkOrder;
  updateAdminUser: AdminUser;
  updateAsset: Asset;
  updateAssetSpecs: Asset;
  updateCategory: Category;
  updateClient: Client;
  updateClientUser: ClientUser;
  updateClientUserByAdmin: ClientUser;
  updateLocation: Location;
  updateMake: Make;
  updateModel: Model;
  updateRegion: Region;
  updateSchedule: Schedule;
  updateScheduleStep: ScheduleStep;
  updateStep: Step;
  updateTask: Task;
  updateTaskGroup: TaskGroup;
  updateUserWidgetSettings: ClientUser;
  updateWorkOrderCompletionDate: WorkOrder;
  updateWorkOrderCosts: WorkOrder;
  updateWorkOrderCurrentDays: WorkOrder;
  updateWorkOrderCurrentEngineHours: WorkOrder;
  updateWorkOrderCurrentOdometer: WorkOrder;
  updateWorkOrderDetails: WorkOrder;
  updateWorkOrderIdentifier: WorkOrder;
  updateWorkOrderMechanic: WorkOrder;
  updateWorkOrderParts: WorkOrder;
  updateWorkOrderStatus: WorkOrder;
  updateWorkOrderTaskActualParameter: WorkOrderTask;
  verifyAdminUser: VerifyAdminUserPayload;
  verifyClientUser: VerifyUserPayload;
};

export type MutationAttachAssetImageArgs = {
  id: Scalars["ID"];
  blobId: Scalars["String"];
};

export type MutationAttachAssetInvoiceArgs = {
  id: Scalars["ID"];
  blobId: Scalars["String"];
};

export type MutationAttachWorkOrderFileArgs = {
  id: Scalars["ID"];
  fileBlobs: Array<Scalars["String"]>;
};

export type MutationChangeWorkOrderScheduleStepArgs = {
  id: Scalars["ID"];
  scheduleStepId: Scalars["ID"];
};

export type MutationCreateAdminUserArgs = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
  phoneNumber: Scalars["String"];
  roleId: Scalars["ID"];
};

export type MutationCreateAssetArgs = {
  identifier: Scalars["String"];
  makeId?: Maybe<Scalars["ID"]>;
  makeName?: Maybe<Scalars["String"]>;
  modelId?: Maybe<Scalars["ID"]>;
  modelName?: Maybe<Scalars["String"]>;
  categoryId?: Maybe<Scalars["ID"]>;
  categoryName?: Maybe<Scalars["String"]>;
  vin?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["Int"]>;
  assetClass?: Maybe<Scalars["String"]>;
  licensePlate?: Maybe<Scalars["String"]>;
  registrationDate?: Maybe<Scalars["ISO8601Date"]>;
  fuelType?: Maybe<Scalars["String"]>;
  currentOdo?: Maybe<Scalars["Float"]>;
  currentHourMeter?: Maybe<Scalars["Float"]>;
  currentDays?: Maybe<Scalars["Float"]>;
  status?: Maybe<AssetStatusEnum>;
  geolocation?: Maybe<Scalars["String"]>;
  site?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  kind: KindEnum;
  disabled: Scalars["Boolean"];
  state?: Maybe<Scalars["String"]>;
  regionId?: Maybe<Scalars["ID"]>;
  locationId?: Maybe<Scalars["ID"]>;
  mainImageId?: Maybe<Scalars["ID"]>;
  imageBlobs?: Maybe<Array<Scalars["String"]>>;
  invoiceBlobs?: Maybe<Array<Scalars["String"]>>;
};

export type MutationCreateCategoryArgs = {
  name: Scalars["String"];
};

export type MutationCreateClientArgs = {
  active: Scalars["Boolean"];
  identifier: Scalars["String"];
  companyName: Scalars["String"];
  streetAddress: Scalars["String"];
  postTown?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  state: ClientStateEnum;
  zip: Scalars["String"];
  owner: CreateOwnerInput;
};

export type MutationCreateClientUserArgs = {
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  email: Scalars["String"];
  phoneNumber: Scalars["String"];
  roleId: Scalars["ID"];
  clientId?: Maybe<Scalars["ID"]>;
  regionIds: Array<Scalars["ID"]>;
  locationIds: Array<Scalars["ID"]>;
  allRegions?: Maybe<Scalars["Boolean"]>;
};

export type MutationCreateDirectApolloUploadArgs = {
  files: Array<Scalars["Upload"]>;
};

export type MutationCreateDirectUploadArgs = {
  input: DirectUploadInput;
};

export type MutationCreateLocationsArgs = {
  regionId: Scalars["ID"];
  locations: Array<CreateLocationInput>;
};

export type MutationCreateMakeArgs = {
  name: Scalars["String"];
};

export type MutationCreateModelsArgs = {
  makeId: Scalars["ID"];
  models: Array<CreateModelInput>;
};

export type MutationCreateRegionArgs = {
  name: Scalars["String"];
};

export type MutationCreateScheduleArgs = {
  name: Scalars["String"];
  timeNotification?: Maybe<Scalars["Boolean"]>;
  engineHourNotification?: Maybe<Scalars["Boolean"]>;
  mileageNotification?: Maybe<Scalars["Boolean"]>;
  intervalTime?: Maybe<Scalars["Int"]>;
  intervalEngineHour?: Maybe<Scalars["Int"]>;
  intervalMileage?: Maybe<Scalars["Int"]>;
  uwtTime?: Maybe<Scalars["Int"]>;
  uwtEngineHour?: Maybe<Scalars["Int"]>;
  uwtMileage?: Maybe<Scalars["Int"]>;
  assetIds?: Maybe<Array<Scalars["ID"]>>;
  categoriesNames?: Maybe<Array<Scalars["String"]>>;
  scheduleSteps: Array<ScheduleStepsInput>;
};

export type MutationCreateScheduleStepArgs = {
  scheduleId: Scalars["ID"];
  stepId: Scalars["ID"];
  position: Scalars["Int"];
};

export type MutationCreateServiceArgs = {
  name: Scalars["String"];
  taskIds: Array<Scalars["ID"]>;
  assetId: Scalars["ID"];
  userId?: Maybe<Scalars["ID"]>;
  deadline: Scalars["ISO8601DateTime"];
};

export type MutationCreateStepArgs = {
  name: Scalars["String"];
  taskIds: Array<Scalars["ID"]>;
};

export type MutationCreateTaskArgs = {
  name: Scalars["String"];
  unit?: Maybe<Scalars["String"]>;
  taskGroupIds?: Maybe<Array<Scalars["ID"]>>;
};

export type MutationCreateTaskGroupArgs = {
  name: Scalars["String"];
  taskIds?: Maybe<Array<Scalars["ID"]>>;
};

export type MutationDeleteAttachmentArgs = {
  id: Scalars["ID"];
};

export type MutationDestroyAdminUserArgs = {
  id: Scalars["ID"];
};

export type MutationDestroyAssetArgs = {
  id: Scalars["ID"];
};

export type MutationDestroyCategoryArgs = {
  id: Scalars["ID"];
};

export type MutationDestroyClientUserArgs = {
  id: Scalars["ID"];
};

export type MutationDestroyClientUserByAdminArgs = {
  id: Scalars["ID"];
  clientId: Scalars["ID"];
};

export type MutationDestroyLocationArgs = {
  id: Scalars["ID"];
};

export type MutationDestroyMakeArgs = {
  id: Scalars["ID"];
};

export type MutationDestroyModelArgs = {
  id: Scalars["ID"];
};

export type MutationDestroyRegionArgs = {
  id: Scalars["ID"];
};

export type MutationDestroyScheduleArgs = {
  id: Scalars["ID"];
};

export type MutationDestroyScheduleStepArgs = {
  id: Scalars["ID"];
};

export type MutationDestroyStepArgs = {
  id: Scalars["ID"];
};

export type MutationDestroyTaskGroupArgs = {
  id: Scalars["ID"];
};

export type MutationImportAssetArgs = {
  file: Scalars["Upload"];
};

export type MutationImportTaskArgs = {
  file: Scalars["Upload"];
};

export type MutationInactivateTaskArgs = {
  id: Scalars["ID"];
};

export type MutationLoadWorkOrderPdfFileArgs = {
  id: Scalars["ID"];
  blobId: Scalars["String"];
};

export type MutationSaveAndCloseWorkOrderArgs = {
  id: Scalars["ID"];
  currentDays?: Maybe<Scalars["Float"]>;
  currentEngineHours?: Maybe<Scalars["Float"]>;
  currentOdometer?: Maybe<Scalars["Float"]>;
};

export type MutationSigninAdminUserArgs = {
  credentials?: Maybe<Auth_Provider_Credentials>;
};

export type MutationSigninAsClientArgs = {
  clientId: Scalars["ID"];
};

export type MutationSigninClientUserArgs = {
  credentials?: Maybe<Auth_Provider_Credentials>;
};

export type MutationToggleWorkOrderTasksArgs = {
  id: Scalars["ID"];
  taskIds: Array<Scalars["ID"]>;
  selected: Scalars["Boolean"];
};

export type MutationUpdateAdminUserArgs = {
  id: Scalars["ID"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  roleId?: Maybe<Scalars["ID"]>;
  active?: Maybe<Scalars["Boolean"]>;
};

export type MutationUpdateAssetArgs = {
  id: Scalars["ID"];
  identifier?: Maybe<Scalars["String"]>;
  makeId?: Maybe<Scalars["ID"]>;
  makeName?: Maybe<Scalars["String"]>;
  modelId?: Maybe<Scalars["ID"]>;
  modelName?: Maybe<Scalars["String"]>;
  categoryId?: Maybe<Scalars["ID"]>;
  categoryName?: Maybe<Scalars["String"]>;
  site?: Maybe<Scalars["String"]>;
  vin?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["Int"]>;
  assetClass?: Maybe<Scalars["String"]>;
  licensePlate?: Maybe<Scalars["String"]>;
  registrationDate?: Maybe<Scalars["ISO8601Date"]>;
  fuelType?: Maybe<Scalars["String"]>;
  currentOdo?: Maybe<Scalars["Float"]>;
  currentHourMeter?: Maybe<Scalars["Float"]>;
  currentDays?: Maybe<Scalars["Float"]>;
  geolocation?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  kind?: Maybe<KindEnum>;
  disabled?: Maybe<Scalars["Boolean"]>;
  state?: Maybe<Scalars["String"]>;
  regionId?: Maybe<Scalars["ID"]>;
  locationId?: Maybe<Scalars["ID"]>;
  scheduleIds?: Maybe<Array<Scalars["ID"]>>;
  status?: Maybe<AssetStatusEnum>;
  mainImageId?: Maybe<Scalars["ID"]>;
  imageBlobs?: Maybe<Array<Scalars["String"]>>;
  invoiceBlobs?: Maybe<Array<Scalars["String"]>>;
};

export type MutationUpdateAssetSpecsArgs = {
  id: Scalars["ID"];
  updateAssetSpecs: Array<Maybe<UpdateAssetSpec>>;
};

export type MutationUpdateCategoryArgs = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type MutationUpdateClientArgs = {
  id: Scalars["ID"];
  active?: Maybe<Scalars["Boolean"]>;
  companyName?: Maybe<Scalars["String"]>;
  streetAddress?: Maybe<Scalars["String"]>;
  postTown?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  state?: Maybe<ClientStateEnum>;
  zip?: Maybe<Scalars["String"]>;
  ownerId?: Maybe<Scalars["ID"]>;
  owner?: Maybe<UpdateOwnerInput>;
};

export type MutationUpdateClientUserArgs = {
  id: Scalars["ID"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  roleId?: Maybe<Scalars["ID"]>;
  active?: Maybe<Scalars["Boolean"]>;
  regionIds?: Maybe<Array<Scalars["ID"]>>;
  locationIds?: Maybe<Array<Scalars["ID"]>>;
  allRegions?: Maybe<Scalars["Boolean"]>;
};

export type MutationUpdateClientUserByAdminArgs = {
  id: Scalars["ID"];
  clientId: Scalars["ID"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  roleId?: Maybe<Scalars["ID"]>;
  active?: Maybe<Scalars["Boolean"]>;
  regionIds?: Maybe<Array<Scalars["ID"]>>;
  locationIds?: Maybe<Array<Scalars["ID"]>>;
  allRegions?: Maybe<Scalars["Boolean"]>;
};

export type MutationUpdateLocationArgs = {
  id: Scalars["ID"];
  regionId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type MutationUpdateMakeArgs = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type MutationUpdateModelArgs = {
  id: Scalars["ID"];
  makeId?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type MutationUpdateRegionArgs = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type MutationUpdateScheduleArgs = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  timeNotification?: Maybe<Scalars["Boolean"]>;
  engineHourNotification?: Maybe<Scalars["Boolean"]>;
  mileageNotification?: Maybe<Scalars["Boolean"]>;
  intervalTime?: Maybe<Scalars["Int"]>;
  intervalEngineHour?: Maybe<Scalars["Int"]>;
  intervalMileage?: Maybe<Scalars["Int"]>;
  uwtTime?: Maybe<Scalars["Int"]>;
  uwtEngineHour?: Maybe<Scalars["Int"]>;
  uwtMileage?: Maybe<Scalars["Int"]>;
  assetIds?: Maybe<Array<Scalars["ID"]>>;
  categoriesNames?: Maybe<Array<Scalars["String"]>>;
  scheduleSteps?: Maybe<Array<ScheduleStepsInput>>;
};

export type MutationUpdateScheduleStepArgs = {
  id: Scalars["ID"];
  scheduleId?: Maybe<Scalars["ID"]>;
  stepId?: Maybe<Scalars["ID"]>;
  position?: Maybe<Scalars["Int"]>;
};

export type MutationUpdateStepArgs = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  taskIds?: Maybe<Array<Scalars["ID"]>>;
};

export type MutationUpdateTaskArgs = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
  taskGroupIds?: Maybe<Array<Scalars["ID"]>>;
};

export type MutationUpdateTaskGroupArgs = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  taskIds?: Maybe<Array<Scalars["ID"]>>;
};

export type MutationUpdateUserWidgetSettingsArgs = {
  id: Scalars["ID"];
  widgetSettings: Scalars["JSON"];
};

export type MutationUpdateWorkOrderCompletionDateArgs = {
  id: Scalars["ID"];
  completionDate: Scalars["ISO8601Date"];
};

export type MutationUpdateWorkOrderCostsArgs = {
  id: Scalars["ID"];
  costs: Scalars["Float"];
};

export type MutationUpdateWorkOrderCurrentDaysArgs = {
  id: Scalars["ID"];
  currentDays: Scalars["Float"];
};

export type MutationUpdateWorkOrderCurrentEngineHoursArgs = {
  id: Scalars["ID"];
  currentEngineHours: Scalars["Float"];
};

export type MutationUpdateWorkOrderCurrentOdometerArgs = {
  id: Scalars["ID"];
  currentOdometer: Scalars["Float"];
};

export type MutationUpdateWorkOrderDetailsArgs = {
  id: Scalars["ID"];
  details: Scalars["String"];
};

export type MutationUpdateWorkOrderIdentifierArgs = {
  id: Scalars["ID"];
  identifier: Scalars["String"];
};

export type MutationUpdateWorkOrderMechanicArgs = {
  id: Scalars["ID"];
  mechanic: Scalars["String"];
};

export type MutationUpdateWorkOrderPartsArgs = {
  id: Scalars["ID"];
  parts: Scalars["String"];
};

export type MutationUpdateWorkOrderStatusArgs = {
  id: Scalars["ID"];
  status: WorkOrderStatusEnum;
};

export type MutationUpdateWorkOrderTaskActualParameterArgs = {
  workOrderId: Scalars["ID"];
  taskId: Scalars["ID"];
  actualParameter: Scalars["String"];
};

export type MutationVerifyAdminUserArgs = {
  credentials?: Maybe<Auth_Provider_Credentials>;
};

export type MutationVerifyClientUserArgs = {
  credentials?: Maybe<Auth_Provider_Credentials>;
};

/** Attributes for order by */
export type OrderByAttributes = {
  /** Table name */
  table: Scalars["String"];
  /** Field title */
  field: Scalars["String"];
  direction?: Maybe<DirectionEnum>;
};

export type Page = {
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  pagesCount: Scalars["Int"];
  totalCount: Scalars["Int"];
};

export type PageAsset = BasePolicy & {
  nodes: Array<Asset>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageAssetPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageAssetSchedule = BasePolicy & {
  nodes: Array<AssetSchedule>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageAssetSchedulePoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageCategory = BasePolicy & {
  nodes: Array<Category>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageCategoryPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageClient = BasePolicy & {
  nodes: Array<ClientsIndex>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageClientPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageDashboardService = BasePolicy & {
  nodes: Array<DashboardService>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageDashboardServicePoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageIndexAsset = BasePolicy & {
  nodes: Array<IndexAsset>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageIndexAssetPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageLocation = BasePolicy & {
  nodes: Array<Location>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageLocationPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageMaintenance = BasePolicy & {
  nodes: Array<Maintenance>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageMaintenancePoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageMake = BasePolicy & {
  nodes: Array<Make>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageMakePoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageModel = BasePolicy & {
  nodes: Array<Model>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageModelPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageRegion = BasePolicy & {
  nodes: Array<Region>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageRegionPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageSchedule = BasePolicy & {
  nodes: Array<Schedule>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageSchedulePoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageService = BasePolicy & {
  nodes: Array<Service>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageServicePoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageServiceHistory = BasePolicy & {
  nodes: Array<ServiceHistory>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageServiceHistoryPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageStep = BasePolicy & {
  nodes: Array<Step>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageStepPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageTask = BasePolicy & {
  nodes: Array<Task>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageTaskPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageTaskGroup = BasePolicy & {
  nodes: Array<TaskGroup>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageTaskGroupPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageUserLocation = BasePolicy & {
  nodes: Array<UserLocation>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageUserLocationPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageUserManagement = BasePolicy & {
  nodes: Array<UserManagement>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageUserManagementPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageUserRegion = BasePolicy & {
  nodes: Array<UserRegion>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageUserRegionPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type PageWorkOrder = BasePolicy & {
  nodes: Array<WorkOrder>;
  pagination: Page;
  policies: Array<Policy>;
};

export type PageWorkOrderPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type Policy = {
  name: Scalars["String"];
  policyRules: Array<PolicyRule>;
};

export type PolicyRule = {
  action: Scalars["String"];
  allow: Scalars["Boolean"];
};

export type Query = {
  /** Find AdminUser. */
  adminUser: AdminUser;
  adminUserManagement: PageUserManagement;
  /** Query AdminUsers with filters. */
  adminUsers: Array<AdminUser>;
  /** Find Asset. */
  asset: Asset;
  assetHistory: AssetHistory;
  assetSchedule: AssetSchedule;
  assetSchedules: PageAssetSchedule;
  /** Query Assets with filters. */
  assets: PageAsset;
  assetsIndex: PageIndexAsset;
  categories: PageCategory;
  category: Category;
  /** Find Client. */
  client: Client;
  clientUser: ClientUser;
  clientUserByClient: ClientUser;
  clientUserManagement: PageUserManagement;
  clientUsers: Array<ClientUser>;
  /** Query Clients with filters. */
  clients: PageClient;
  clientsIndex: PageClient;
  dashboardServices: PageDashboardService;
  location: Location;
  locations: PageLocation;
  maintenances: PageMaintenance;
  /** Find Make. */
  make: Make;
  /** Query Makes with filters. */
  makes: PageMake;
  model: Model;
  models: PageModel;
  region: Region;
  regions: PageRegion;
  roles: Array<Role>;
  schedule: Schedule;
  schedules: PageSchedule;
  searchAssets: PageAsset;
  searchDashboard: PageAssetSchedule;
  /** Find Service. */
  service: Service;
  serviceHistory: PageServiceHistory;
  /** Query Services with filters. */
  services: PageService;
  servicesWithOpenWorkOrders: PageService;
  /** Find Step. */
  step: Step;
  /** Query Steps with filters. */
  steps: PageStep;
  /** Find Task. */
  task: Task;
  /** Find TaskGroup. */
  taskGroup: TaskGroup;
  /** Query TaskGroups with filters. */
  taskGroups: PageTaskGroup;
  /** Query Tasks with filters. */
  tasks: PageTask;
  userLocation: UserLocation;
  userLocations: PageUserLocation;
  userRegion: UserRegion;
  userRegions: PageUserRegion;
  widgetCurrentMaintenance: CurrentMaintenance;
  widgetWorkOrdersByStatus: WorkOrdersByStatus;
  workOrder: WorkOrder;
  workOrders: PageWorkOrder;
};

export type QueryAdminUserArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryAdminUserManagementArgs = {
  orderBy?: Maybe<UserManagementSort>;
  filter?: Maybe<UserManagementFilter>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryAdminUsersArgs = {
  sortBy?: Maybe<Array<Scalars["String"]>>;
  orderBy?: Maybe<OrderByAttributes>;
};

export type QueryAssetArgs = {
  mainImageId?: Maybe<Scalars["ID"]>;
  id?: Maybe<Scalars["ID"]>;
};

export type QueryAssetHistoryArgs = {
  assetId: Scalars["ID"];
  date: Scalars["ISO8601Date"];
};

export type QueryAssetScheduleArgs = {
  id: Scalars["ID"];
};

export type QueryAssetSchedulesArgs = {
  filter?: Maybe<AssetScheduleFilter>;
  orderBy?: Maybe<AssetScheduleSort>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryAssetsArgs = {
  filter?: Maybe<AssetFilter>;
  sortBy?: Maybe<Array<Scalars["String"]>>;
  orderBy?: Maybe<OrderByAttributes>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryAssetsIndexArgs = {
  filter?: Maybe<IndexAssetFilter>;
  orderBy?: Maybe<IndexAssetSort>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  search?: Maybe<Scalars["String"]>;
};

export type QueryCategoriesArgs = {
  filter?: Maybe<CategoryFilter>;
  orderBy?: Maybe<CategorySort>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryCategoryArgs = {
  id: Scalars["ID"];
};

export type QueryClientArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryClientUserArgs = {
  id: Scalars["ID"];
};

export type QueryClientUserByClientArgs = {
  id: Scalars["ID"];
  clientId: Scalars["ID"];
};

export type QueryClientUserManagementArgs = {
  orderBy?: Maybe<UserManagementSort>;
  filter?: Maybe<UserManagementFilter>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  clientId?: Maybe<Scalars["ID"]>;
};

export type QueryClientUsersArgs = {
  filter?: Maybe<ClientUsersFilter>;
  orderBy?: Maybe<ClientUsersSort>;
  search?: Maybe<Scalars["String"]>;
};

export type QueryClientsArgs = {
  sortBy?: Maybe<Array<Scalars["String"]>>;
  orderBy?: Maybe<OrderByAttributes>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryClientsIndexArgs = {
  orderBy?: Maybe<ClientsIndexSort>;
  search?: Maybe<Scalars["String"]>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryDashboardServicesArgs = {
  search?: Maybe<Scalars["String"]>;
  filter?: Maybe<DashboardServiceFilter>;
  orderBy?: Maybe<DashboardServiceSort>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  dateTime?: Maybe<Scalars["ISO8601DateTime"]>;
};

export type QueryLocationArgs = {
  id: Scalars["ID"];
};

export type QueryLocationsArgs = {
  filter?: Maybe<LocationFilter>;
  orderBy?: Maybe<LocationSort>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  clientId?: Maybe<Scalars["ID"]>;
};

export type QueryMaintenancesArgs = {
  search?: Maybe<Scalars["String"]>;
  filter?: Maybe<MaintenanceFilter>;
  orderBy?: Maybe<MaintenanceSort>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryMakeArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryMakesArgs = {
  sortBy?: Maybe<Array<Scalars["String"]>>;
  orderBy?: Maybe<OrderByAttributes>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryModelArgs = {
  id: Scalars["ID"];
};

export type QueryModelsArgs = {
  filter?: Maybe<ModelFilter>;
  orderBy?: Maybe<ModelSort>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryRegionArgs = {
  id: Scalars["ID"];
};

export type QueryRegionsArgs = {
  filter?: Maybe<RegionFilter>;
  orderBy?: Maybe<RegionSort>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  clientId?: Maybe<Scalars["ID"]>;
};

export type QueryRolesArgs = {
  admin?: Maybe<Scalars["Boolean"]>;
};

export type QueryScheduleArgs = {
  id: Scalars["ID"];
};

export type QuerySchedulesArgs = {
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  filter?: Maybe<ScheduleFilter>;
  orderBy?: Maybe<ScheduleSort>;
};

export type QuerySearchAssetsArgs = {
  search?: Maybe<Scalars["String"]>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QuerySearchDashboardArgs = {
  search?: Maybe<Scalars["String"]>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryServiceArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryServiceHistoryArgs = {
  assetId: Scalars["ID"];
  orderBy?: Maybe<ServiceHistorySort>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryServicesArgs = {
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
  orderBy?: Maybe<OrderByAttributes>;
  assetId?: Maybe<Scalars["ID"]>;
  closed?: Maybe<Scalars["Boolean"]>;
};

export type QueryServicesWithOpenWorkOrdersArgs = {
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryStepArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryStepsArgs = {
  sortBy?: Maybe<Array<Scalars["String"]>>;
  orderBy?: Maybe<OrderByAttributes>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryTaskArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryTaskGroupArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryTaskGroupsArgs = {
  sortBy?: Maybe<Array<Scalars["String"]>>;
  orderBy?: Maybe<OrderByAttributes>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryTasksArgs = {
  sortBy?: Maybe<Array<Scalars["String"]>>;
  orderBy?: Maybe<OrderByAttributes>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryUserLocationArgs = {
  id: Scalars["ID"];
};

export type QueryUserLocationsArgs = {
  filter?: Maybe<UserLocationFilter>;
  orderBy?: Maybe<UserLocationSort>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryUserRegionArgs = {
  id: Scalars["ID"];
};

export type QueryUserRegionsArgs = {
  filter?: Maybe<UserRegionFilter>;
  orderBy?: Maybe<UserRegionSort>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type QueryWorkOrderArgs = {
  id: Scalars["ID"];
};

export type QueryWorkOrdersArgs = {
  filter?: Maybe<WorkOrderFilter>;
  orderBy?: Maybe<WorkOrderSort>;
  pageSize?: Maybe<Scalars["Int"]>;
  pageNumber?: Maybe<Scalars["Int"]>;
};

export type Record = {
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  updatedAt: Scalars["ISO8601DateTime"];
};

export type Region = BasePolicy &
  Record & {
    assets: Array<Asset>;
    createdAt: Scalars["ISO8601DateTime"];
    id: Scalars["ID"];
    locations: Array<Location>;
    name: Scalars["String"];
    policies: Array<Policy>;
    updatedAt: Scalars["ISO8601DateTime"];
  };

export type RegionPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type RegionArrayFields = {
  id?: Maybe<Array<Scalars["ID"]>>;
  name?: Maybe<Array<Scalars["String"]>>;
};

export type RegionFields = {
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type RegionFilter = {
  OR?: Maybe<RegionFilter>;
  equal?: Maybe<RegionFields>;
  like?: Maybe<RegionFields>;
  in?: Maybe<RegionArrayFields>;
  nin?: Maybe<RegionArrayFields>;
};

export type RegionSort = {
  id?: Maybe<DirectionEnum>;
  name?: Maybe<DirectionEnum>;
};

export type Role = Record & {
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  name: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
};

export type Schedule = BasePolicy &
  Record & {
    assets: Array<Asset>;
    createdAt: Scalars["ISO8601DateTime"];
    engineHourNotification?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    intervalEngineHour?: Maybe<Scalars["Int"]>;
    intervalMileage?: Maybe<Scalars["Int"]>;
    intervalTime?: Maybe<Scalars["Int"]>;
    mileageNotification?: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    policies: Array<Policy>;
    scheduleSteps: Array<ScheduleStep>;
    steps: Array<Step>;
    timeNotification?: Maybe<Scalars["Boolean"]>;
    updatedAt: Scalars["ISO8601DateTime"];
    uwtEngineHour?: Maybe<Scalars["Int"]>;
    uwtMileage?: Maybe<Scalars["Int"]>;
    uwtTime?: Maybe<Scalars["Int"]>;
  };

export type SchedulePoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type ScheduleArrayFields = {
  name?: Maybe<Array<Scalars["String"]>>;
  deleted?: Maybe<Array<Scalars["Boolean"]>>;
};

export type ScheduleFields = {
  name?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["Boolean"]>;
};

export type ScheduleFilter = {
  OR?: Maybe<ScheduleFilter>;
  equal?: Maybe<ScheduleFields>;
  like?: Maybe<ScheduleFields>;
  in?: Maybe<ScheduleArrayFields>;
  nin?: Maybe<ScheduleArrayFields>;
};

export type ScheduleSort = {
  name?: Maybe<DirectionEnum>;
};

export type ScheduleStep = Record & {
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  position: Scalars["Int"];
  scheduleId: Scalars["ID"];
  step?: Maybe<Step>;
  stepId: Scalars["ID"];
  updatedAt: Scalars["ISO8601DateTime"];
};

export type ScheduleStepsInput = {
  id?: Maybe<Scalars["ID"]>;
  position: Scalars["Int"];
  stepId?: Maybe<Scalars["ID"]>;
};

export type Service = Record & {
  asset: Asset;
  createdAt: Scalars["ISO8601DateTime"];
  createdBy: Scalars["String"];
  deadline: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  name: Scalars["String"];
  tasks: Array<Task>;
  updatedAt: Scalars["ISO8601DateTime"];
  user?: Maybe<ClientUser>;
  workOrder: WorkOrder;
};

export type ServiceHistory = {
  assetId: Scalars["Int"];
  files: Array<Attachment>;
  pdfFile?: Maybe<Attachment>;
  scheduleName?: Maybe<Scalars["String"]>;
  serviceName: Scalars["String"];
  wo: Scalars["String"];
  woCompletionDate?: Maybe<Scalars["ISO8601DateTime"]>;
  woId: Scalars["Int"];
};

export type ServiceHistorySort = {
  serviceName?: Maybe<DirectionEnum>;
  scheduleName?: Maybe<DirectionEnum>;
  wo?: Maybe<DirectionEnum>;
  woCompletionDate?: Maybe<DirectionEnum>;
};

/** Autogenerated return type of SignInAdminUser */
export type SignInAdminUserPayload = {
  adminUser: AdminUser;
  authToken: Scalars["String"];
};

/** Autogenerated return type of SignInAsClient */
export type SignInAsClientPayload = {
  adminUser: AdminUser;
  authToken: Scalars["String"];
  clientId: Scalars["ID"];
};

/** Autogenerated return type of SignInCheckAdminUser */
export type SignInCheckAdminUserPayload = {
  adminUser: AdminUser;
};

/** Autogenerated return type of SignInCheckUser */
export type SignInCheckUserPayload = {
  user: ClientUser;
};

/** Autogenerated return type of SignInUser */
export type SignInUserPayload = {
  authToken: Scalars["String"];
  user: ClientUser;
};

/** Autogenerated return type of SignOutAdminUser */
export type SignOutAdminUserPayload = {
  status: Scalars["Boolean"];
};

/** Autogenerated return type of SignOutAsClient */
export type SignOutAsClientPayload = {
  adminUser: AdminUser;
  authToken: Scalars["String"];
};

/** Autogenerated return type of SignOutUser */
export type SignOutUserPayload = {
  status: Scalars["Boolean"];
};

export type Step = BasePolicy &
  Record & {
    createdAt: Scalars["ISO8601DateTime"];
    id: Scalars["ID"];
    name: Scalars["String"];
    policies: Array<Policy>;
    schedules: Array<Schedule>;
    tasks: Array<Task>;
    updatedAt: Scalars["ISO8601DateTime"];
  };

export type StepPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type Task = BasePolicy &
  Record & {
    createdAt: Scalars["ISO8601DateTime"];
    id: Scalars["ID"];
    inactive?: Maybe<Scalars["Boolean"]>;
    name: Scalars["String"];
    policies: Array<Policy>;
    steps: Array<Step>;
    stepsSize?: Maybe<Scalars["Int"]>;
    taskGroups: Array<TaskGroup>;
    unit?: Maybe<Scalars["String"]>;
    updatedAt: Scalars["ISO8601DateTime"];
  };

export type TaskPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type TaskGroup = BasePolicy &
  Record & {
    createdAt: Scalars["ISO8601DateTime"];
    id: Scalars["ID"];
    name: Scalars["String"];
    policies: Array<Policy>;
    tasks: Array<Task>;
    updatedAt: Scalars["ISO8601DateTime"];
  };

export type TaskGroupPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

/** Input for a Asset Spec */
export type UpdateAssetSpec = {
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** Client's owner update attributes */
export type UpdateOwnerInput = {
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type UserLocation = BasePolicy &
  Record & {
    createdAt: Scalars["ISO8601DateTime"];
    id: Scalars["ID"];
    location: Location;
    locationId: Scalars["ID"];
    policies: Array<Policy>;
    updatedAt: Scalars["ISO8601DateTime"];
    userId: Scalars["ID"];
  };

export type UserLocationPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type UserLocationArrayFields = {
  id?: Maybe<Array<Scalars["ID"]>>;
  locationId?: Maybe<Array<Scalars["ID"]>>;
  locationName?: Maybe<Array<Scalars["String"]>>;
};

export type UserLocationFields = {
  id?: Maybe<Scalars["ID"]>;
  locationId?: Maybe<Scalars["ID"]>;
  locationName?: Maybe<Scalars["String"]>;
};

export type UserLocationFilter = {
  OR?: Maybe<UserLocationFilter>;
  equal?: Maybe<UserLocationFields>;
  like?: Maybe<UserLocationFields>;
  in?: Maybe<UserLocationArrayFields>;
  nin?: Maybe<UserLocationArrayFields>;
};

export type UserLocationSort = {
  id?: Maybe<DirectionEnum>;
  locationId?: Maybe<DirectionEnum>;
  locationName?: Maybe<DirectionEnum>;
};

export type UserManagement = BasePolicy & {
  active: Scalars["Boolean"];
  createdAt: Scalars["ISO8601DateTime"];
  email: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  owner: Scalars["Boolean"];
  phoneNumber: Scalars["String"];
  policies: Array<Policy>;
  role: Scalars["String"];
};

export type UserManagementPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type UserManagementFields = {
  name?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  owner?: Maybe<Scalars["Boolean"]>;
};

export type UserManagementFilter = {
  OR?: Maybe<UserManagementFilter>;
  equal?: Maybe<UserManagementFields>;
  like?: Maybe<UserManagementFields>;
  in?: Maybe<UserManagementFields>;
  nin?: Maybe<UserManagementFields>;
};

export type UserManagementSort = {
  name?: Maybe<DirectionEnum>;
  role?: Maybe<DirectionEnum>;
  email?: Maybe<DirectionEnum>;
  phoneNumber?: Maybe<DirectionEnum>;
  owner?: Maybe<DirectionEnum>;
  createdAt?: Maybe<DirectionEnum>;
};

export type UserRegion = BasePolicy &
  Record & {
    createdAt: Scalars["ISO8601DateTime"];
    id: Scalars["ID"];
    policies: Array<Policy>;
    region: Region;
    regionId: Scalars["ID"];
    updatedAt: Scalars["ISO8601DateTime"];
    userId: Scalars["ID"];
  };

export type UserRegionPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type UserRegionArrayFields = {
  id?: Maybe<Array<Scalars["ID"]>>;
  regionId?: Maybe<Array<Scalars["ID"]>>;
  regionName?: Maybe<Array<Scalars["String"]>>;
};

export type UserRegionFields = {
  id?: Maybe<Scalars["ID"]>;
  regionId?: Maybe<Scalars["ID"]>;
  regionName?: Maybe<Scalars["String"]>;
};

export type UserRegionFilter = {
  OR?: Maybe<UserRegionFilter>;
  equal?: Maybe<UserRegionFields>;
  like?: Maybe<UserRegionFields>;
  in?: Maybe<UserRegionArrayFields>;
  nin?: Maybe<UserRegionArrayFields>;
};

export type UserRegionSort = {
  id?: Maybe<DirectionEnum>;
  regionId?: Maybe<DirectionEnum>;
  regionName?: Maybe<DirectionEnum>;
};

/** Autogenerated return type of VerifyAdminUser */
export type VerifyAdminUserPayload = {
  adminUser: AdminUser;
};

/** Autogenerated return type of VerifyUser */
export type VerifyUserPayload = {
  user: ClientUser;
};

export type WorkOrder = BasePolicy &
  Record & {
    allTasks: Array<AllTask>;
    asset: Asset;
    assetSchedule?: Maybe<AssetSchedule>;
    changedByUser: Scalars["Boolean"];
    closed: Scalars["Boolean"];
    closingDate?: Maybe<Scalars["ISO8601DateTime"]>;
    completionDate: Scalars["ISO8601Date"];
    costs?: Maybe<Scalars["Float"]>;
    createdAt: Scalars["ISO8601DateTime"];
    currentDays?: Maybe<Scalars["Float"]>;
    currentEngineHours?: Maybe<Scalars["Float"]>;
    currentOdometer?: Maybe<Scalars["Float"]>;
    details?: Maybe<Scalars["String"]>;
    files: Array<Attachment>;
    id: Scalars["ID"];
    identifier: Scalars["String"];
    mechanic?: Maybe<Scalars["String"]>;
    mechanicList?: Maybe<Array<Scalars["String"]>>;
    parts?: Maybe<Scalars["String"]>;
    pdfFile?: Maybe<Attachment>;
    policies: Array<Policy>;
    schedule?: Maybe<Schedule>;
    scheduleStep?: Maybe<ScheduleStep>;
    service?: Maybe<Service>;
    serviceDue?: Maybe<Scalars["String"]>;
    status: WorkOrderStatusEnum;
    updatedAt: Scalars["ISO8601DateTime"];
    workOrderTasks: Array<WorkOrderTask>;
  };

export type WorkOrderPoliciesArgs = {
  objects: Array<Scalars["String"]>;
};

export type WorkOrderArrayFields = {
  id?: Maybe<Array<Scalars["ID"]>>;
  identifier?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Array<WorkOrderStatusEnum>>;
  completionDate?: Maybe<Array<Scalars["ISO8601DateTime"]>>;
  parts?: Maybe<Array<Scalars["String"]>>;
  costs?: Maybe<Array<Scalars["Float"]>>;
  mechanic?: Maybe<Array<Scalars["String"]>>;
  details?: Maybe<Array<Scalars["String"]>>;
  currentOdometer?: Maybe<Array<Scalars["Float"]>>;
  currentEngineHours?: Maybe<Array<Scalars["Float"]>>;
  currentDays?: Maybe<Array<Scalars["Float"]>>;
  closed?: Maybe<Array<Scalars["Boolean"]>>;
  closingDate?: Maybe<Array<Scalars["ISO8601DateTime"]>>;
  changedByUser?: Maybe<Array<Scalars["String"]>>;
  assetDisabled?: Maybe<Array<Scalars["Boolean"]>>;
};

export type WorkOrderFields = {
  identifier?: Maybe<Scalars["String"]>;
  status?: Maybe<WorkOrderStatusEnum>;
  completionDate?: Maybe<Scalars["ISO8601DateTime"]>;
  parts?: Maybe<Scalars["String"]>;
  costs?: Maybe<Scalars["Float"]>;
  mechanic?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["String"]>;
  currentOdometer?: Maybe<Scalars["Float"]>;
  currentEngineHours?: Maybe<Scalars["Float"]>;
  currentDays?: Maybe<Scalars["Float"]>;
  closed?: Maybe<Scalars["Boolean"]>;
  closingDate?: Maybe<Scalars["ISO8601DateTime"]>;
  changedByUser?: Maybe<Scalars["String"]>;
  assetDisabled?: Maybe<Scalars["Boolean"]>;
};

export type WorkOrderFilter = {
  OR?: Maybe<WorkOrderFilter>;
  equal?: Maybe<WorkOrderFields>;
  like?: Maybe<WorkOrderFields>;
  in?: Maybe<WorkOrderArrayFields>;
  nin?: Maybe<WorkOrderArrayFields>;
};

export type WorkOrderSort = {
  status?: Maybe<DirectionEnum>;
  createdAt?: Maybe<DirectionEnum>;
  closingDate?: Maybe<DirectionEnum>;
};

export enum WorkOrderStatusEnum {
  Pending = "PENDING",
  Open = "OPEN",
  InProgress = "IN_PROGRESS",
}

export type WorkOrderTask = Record & {
  actualParameter?: Maybe<Scalars["String"]>;
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  selected: Scalars["Boolean"];
  task: Task;
  taskId: Scalars["ID"];
  updatedAt: Scalars["ISO8601DateTime"];
  workOrder: WorkOrder;
  workOrderId: Scalars["ID"];
};

export type WorkOrdersByStatus = {
  inProgress: Scalars["Int"];
  open: Scalars["Int"];
  pending: Scalars["Int"];
  total: Scalars["Int"];
};
