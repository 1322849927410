export const generateOnSelect = (isNew: boolean, value: any) => {
  return isNew
    ? [
        {
          id: value,
          name: value,
        },
      ]
    : [{ id: value, name: "" }];
};
