import * as React from "react";
import { Table as AntTable } from "antd";
import { TableProps as AntdTableProps } from "antd/lib/table";
import { ParsedOrder } from "types";
import { ActionsColumn, IDColumn } from "./components";
import { tableUtils } from "./duck";
import styles from "./Table.module.css";

interface TableProps<TData> extends AntdTableProps<TData> {
  order?: ParsedOrder;
}

function Table<RecordType extends Record<string, unknown>>({
  children,
  rowKey,
  order,
  ...tableProps
}: TableProps<RecordType>) {
  const formattedColumns = React.useMemo(
    () => tableUtils.formatColumns<RecordType>(children, order),
    [children, order]
  );

  return (
    <AntTable<RecordType>
      className={styles.wrapper}
      rowKey={rowKey || "id"}
      columns={formattedColumns}
      bordered
      size="small"
      scroll={{ x: 0 }}
      {...tableProps}
    />
  );
}

Table.Column = AntTable.Column;
Table.ColumnGroup = AntTable.ColumnGroup;
Table.ActionsColumn = ActionsColumn;
Table.IDColumn = IDColumn;

export default Table;
