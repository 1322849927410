import * as React from "react";
import { Typography } from "antd";
import { Link, Menu } from "components";
import { useLocation } from "hooks";
import { selectors, Types } from "./duck";
import styles from "./Navigation.module.css";

const renderLink = (item: Types.MenuItem) => {
  return item.path ? (
    <Link to={item.path}>{item.title}</Link>
  ) : (
    <Typography.Link>{item.title}</Typography.Link>
  );
};

const Navigation: React.FC = () => {
  const { pathname } = useLocation();
  const navigation = selectors.getNavigation();
  const selectedKeys = selectors.getSelectedKeys(navigation, pathname);
  const openKeys = selectors.getOpenKeys(navigation, selectedKeys);

  return (
    <Menu
      className={styles.menu}
      mode="inline"
      selectedKeys={selectedKeys}
      defaultOpenKeys={openKeys}
    >
      {navigation.map((item) => {
        if (item.subMenu?.length) {
          return (
            <Menu.SubMenu
              key={item.title}
              title={item.title}
              icon={item.icon ? React.createElement(item.icon) : undefined}
            >
              {item.subMenu.map((subItem) => {
                return (
                  <Menu.Item key={subItem.title}>
                    {renderLink(subItem)}
                  </Menu.Item>
                );
              })}
            </Menu.SubMenu>
          );
        }

        return (
          <Menu.Item
            key={item.title}
            icon={item.icon ? React.createElement(item.icon) : undefined}
          >
            {renderLink(item)}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default Navigation;
