import * as React from "react";
import moment from "moment";
import * as C from "components";
import { DEFAULT_DATE_FORMAT } from "consts";
import { useQuery } from "hooks";
import { WidgetFC } from "../../duck/types";
import Widget from "../widget";
import { Preview } from "./components";
import { operations, Types, consts } from "./duck";

const columns: C.TableColumnsType<Types.WorkOrder> = [
  {
    title: "",
    dataIndex: "identifier",
    ellipsis: true,
    render: (identifier: string, { id }) => (
      <C.Link to={`/work-order/${id}/edit`}>{identifier}</C.Link>
    ),
  },
  {
    title: "WO status",
    dataIndex: "status",
    ellipsis: true,
    render: (status: string) =>
      consts.statusOptions.find((o) => o.value === status)?.label,
  },
  {
    title: "Creation Date",
    dataIndex: "createdAt",
    align: "center",
    render: (createdAt: number) => (
      <span>{moment(createdAt).format(DEFAULT_DATE_FORMAT)}</span>
    ),
    width: 92,
  },
  {
    title: "Days since Creation",
    key: "days",
    dataIndex: "createdAt",
    align: "center",
    render: (createdAt: number) => (
      <C.Tag color="orange">
        {moment(new Date()).diff(moment(createdAt), "days")}
      </C.Tag>
    ),
  },
];

const WorkOrderByDate: WidgetFC = () => {
  const { data, loading } = useQuery<
    Types.GetEarlierWorkOrderQuery,
    Types.GetEarlierWorkOrderQueryVariables
  >(operations.getEarlierWorkOrder);

  const nodes = data ? data.workOrders.nodes : [];

  return (
    <Widget title="In-Progress" loading={loading}>
      <C.Table
        columns={columns}
        dataSource={nodes}
        pagination={false}
        bordered={false}
        size="small"
      />
    </Widget>
  );
};

WorkOrderByDate.Preview = Preview;

export default WorkOrderByDate;
