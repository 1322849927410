import { scrollIntoView } from "utils";

export const scrollErrorIntoView = (fieldName: string): void => {
  const errorElement = document.querySelector(
    `form [name=${fieldName}], .ant-form-item-has-error`
  );

  if (errorElement) {
    scrollIntoView(errorElement, {
      behavior: "smooth",
      block: "center",
      scrollMode: "if-needed",
    });
  }
};
