import * as React from "react";
import { Tag } from "components";
import styles from "./Due.module.css";

interface DueProps {
  value: React.ReactText | undefined;
  warningAt?: number | null;
  pastAt?: number;
}

const Due: React.FC<DueProps> = ({ value, warningAt, pastAt = 0 }) => {
  if (value === undefined || value === null) {
    return (
      <div className={styles.wrapper}>
        <span>-</span>
      </div>
    );
  }
  const past = value < pastAt;
  const coming =
    !past &&
    warningAt !== undefined &&
    warningAt !== null &&
    value <= warningAt;

  return (
    <div className={styles.wrapper}>
      {coming || past ? (
        <Tag color={coming ? "orange" : "red"}>{value}</Tag>
      ) : (
        <span>{value}</span>
      )}
    </div>
  );
};

export default Due;
