import * as React from "react";
import { useNavigate, useQuery } from "hooks";
import * as Types from "types";
import { parseError, mapPolicies } from "utils";
import { ShowContext } from "./duck";

interface ShowProps<TQueryData, TQueryVariables> {
  query: Types.QueryObject<TQueryVariables> & { key: string };
  children:
    | React.ReactNode
    | ((
        props: Types.ShowConfig<TQueryData, TQueryVariables>
      ) => React.ReactNode);
}

interface ShowData {
  [key: string]: {
    id: string;
    policies?: Types.Policy[];
  };
}

function Show<TQueryData extends ShowData, TQueryVariables>({
  children,
  query,
}: ShowProps<TQueryData, TQueryVariables>) {
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<TQueryData>(query.operation, {
    fetchPolicy: "network-only",
    variables: query.variables,
    onCompleted: (response) => {
      query.options?.onCompleted?.(response);
    },
    onError: (queryError) => {
      /** if code 403 or 404 - just skip and show <Content.Error /> component */
      const { code } = parseError(queryError);

      if (code !== 403 && code !== 404) {
        navigate(-1);
      }
    },
  });

  const config = {
    data,
    loading,
    error,
    policies: mapPolicies(data?.[query.key].policies),
  };

  return (
    <ShowContext.Provider value={config}>
      {typeof children === "function" ? children(config) : children}
    </ShowContext.Provider>
  );
}

export default Show;
