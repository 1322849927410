import * as React from "react";
import * as C from "components";
import { FormItemProps } from "../../duck/types";

const PhoneNumber: React.FC<FormItemProps> = ({ md }) => {
  return (
    <C.Col span={24} md={md}>
      <C.Form.Item name="phoneNumber.number" label="Phone Number" required>
        <C.PhoneNumberInput
          name="phoneNumber"
          placeholder="Enter Phone Number"
        />
      </C.Form.Item>
    </C.Col>
  );
};

export default PhoneNumber;
