import { Pagination, TablePagination } from "types";

export const parsePagination = (pagination: Pagination): TablePagination => ({
  current: pagination.number,
  pageSize: pagination.size,
});

export const preparePagination = (
  paginationConfig: TablePagination
): Pagination => ({
  number: paginationConfig.current,
  size: paginationConfig.pageSize,
});
