import * as React from "react";
import { ApolloError } from "@apollo/client";
import { ERROR_MESSAGE } from "consts";
import { parseError } from "utils";
import { Img403, Img404 } from "./components";
import { Types } from "./duck";
import styles from "./Error.module.css";

interface ErrorProps {
  error?: ApolloError;
  errorCode?: number;
}

const ERRORS: Types.ErrorOptions = {
  403: {
    code: 403,
    title: "Forbidden",
    message: "Access to this resource on the server is denied",
    image: <Img403 />,
  },
  404: {
    code: 404,
    title: "Not Found",
    message: "The resource requested could not be found on this server",
    image: <Img404 />,
  },
  default: {
    title: "Error",
    message: ERROR_MESSAGE,
    image: <Img403 />,
  },
};

const Error: React.FC<ErrorProps> = ({ error, errorCode }) => {
  const code = errorCode || parseError(error).code;
  const { image, title, message } = ERRORS[code] || ERRORS.default;

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        {image}
        {code && <div className={styles.code}>{code}</div>}
        {title && <div className={styles.title}>{title}</div>}
        {message && <div className={styles.message}>{message}</div>}
      </div>
    </div>
  );
};

export default Error;
