import darkHorizontal from "./darkHorizontal.png";
import lightHorizontal from "./lightHorizontal.png";
import vertical from "./vertical.png";

const allLogos = {
  header: darkHorizontal,
  lightVertical: vertical,
  lightHorizontal,
  dark: darkHorizontal,
  darkVertical: vertical,
  darkHorizontal,
};

export default allLogos;
