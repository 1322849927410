import { PossibleLabels } from "./types";

export const colorsByStatus: { [key in PossibleLabels | string]: string } = {
  open: "#408AF9",
  inProgress: "#FFA940",
  pending: "#95DE64",
  total: "",
};

export const labelsByStatus: { [key in PossibleLabels | string]: string } = {
  open: "Open",
  inProgress: "In Progress",
  pending: "Pending",
};
