import * as React from "react";
import { ListContext } from "components/admin/list/duck";
import { OperationData, OperationVariables, ListConfig } from "types";

const useList = <
  TData = OperationData,
  TVariables = OperationVariables
>(): ListConfig<TData, TVariables> =>
  // TODO: Fix TVariables collision to remove this cast.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  React.useContext(ListContext);

export default useList;
