import * as React from "react";
import { MenuItemProps } from "antd";
import { Menu } from "components";

type ItemProps = Omit<MenuItemProps, "children">;

const Item: React.FC<ItemProps> = ({ children, ...rest }) => {
  return <Menu.Item {...rest}>{children}</Menu.Item>;
};

export default Item;
