import * as React from "react";
import { InputProps } from "formik-antd";
import { Form } from "components";
import { FilterOperator } from "../../duck/types";

type TextFilterProps = Omit<InputProps, "name"> & {
  name: string;
  operator: FilterOperator;
};

const TextFilter: React.FC<TextFilterProps> = ({ name, operator, ...rest }) => {
  return <Form.Input name={`${operator}.${name}`} {...rest} />;
};

export default TextFilter;
