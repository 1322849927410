import * as React from "react";
import { ColumnsType } from "antd/lib/table";
import { ParsedOrder } from "types";

const mapColumns =
  <T>(order: ParsedOrder | undefined) =>
  (column: any): ColumnsType<T>[number] => {
    if (column.props.children) {
      return {
        key: column.key,
        ...column.props,
        children: React.Children.map(column.props.children, mapColumns(order)),
      };
    }
    let sortOrder: string | null | undefined;

    if (order) {
      sortOrder = order[column.props.dataIndex];
    }

    return {
      key: column.key,
      ...column.props,
      sortOrder,
    };
  };

export const formatColumns = <T>(
  children: React.ReactNode,
  order: ParsedOrder | undefined
): ColumnsType<T> => React.Children.map(children, mapColumns(order)) || [];
