import * as React from "react";
import { ColumnProps } from "antd/lib/table";

// TODO: Add posibility to pass generic like for default Column component.
// Here can be any type of record.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const IDColumn: React.FC<ColumnProps<any>> = () => <></>;

IDColumn.defaultProps = {
  title: "ID",
  dataIndex: "id",
  width: 74,
};

export default IDColumn;
