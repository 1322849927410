import { ApolloError } from "@apollo/client";

interface ParsedError<TData> {
  messages: string[];
  code: number;
  data: TData | null;
}

export const parseError = <TData>(
  error: ApolloError | unknown
): ParsedError<TData> => {
  const result: ParsedError<TData> = {
    messages: [],
    code: -1,
    data: null,
  };

  if (error instanceof ApolloError) {
    const parsedErrors = JSON.parse(JSON.stringify(error)).graphQLErrors;
    const mainError = parsedErrors[0];

    result.messages = parsedErrors.map(
      (parsedError: { message: string }) => parsedError.message
    );
    result.code = mainError?.code;
    result.data = mainError?.data;
  }

  return result;
};

export const onError = () => {
  // TODO: decide remove empty error handler?
  /**
   * All errors are cached in use-error-hook
   * Have to decide trow Error from hook or not
   * If not - should pass empty OnError everywhere
   * If yes - should replace all .cache and add to .then methods error checking (data.errors)
   * */
};
