import * as React from "react";
import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Button } from "components";
import { Link, Mutation, Item } from "./components";
import styles from "./Actions.module.css";

interface ActionsProps {
  disabled?: boolean;
}

interface ActionsStatic {
  Link: typeof Link;
  Mutation: typeof Mutation;
  Item: typeof Item;
}

const Actions: React.FC<ActionsProps> & ActionsStatic = ({
  disabled,
  children,
}) => {
  return (
    <Dropdown
      overlay={<Menu style={{ minWidth: 120 }}>{children}</Menu>}
      placement="bottomRight"
      trigger={["click"]}
    >
      <Button className={styles.button} disabled={disabled} type="link">
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  );
};

Actions.Link = Link;
Actions.Mutation = Mutation;
Actions.Item = Item;

export default Actions;
