import * as React from "react";
import { PageHeader } from "components";
import { useNavigate } from "hooks";
import { Item, Loading, Error } from "./components";
import styles from "./Content.module.css";

interface ContentProps {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  extra?: React.ReactNode;
  footer?: React.ReactNode;
  backPath?: string | number | null;
  children?: React.ReactNode;
}

const Content = ({
  title,
  subTitle,
  extra,
  footer,
  backPath = -1,
  children,
}: ContentProps): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.header}>
        <PageHeader
          title={title}
          subTitle={subTitle}
          extra={extra}
          footer={footer}
          onBack={backPath ? () => navigate(backPath) : undefined}
        />
      </div>

      <div className={styles.wrapper}>
        <div className={styles.content}>{children}</div>
      </div>
    </>
  );
};

Content.Item = Item;
Content.Loading = Loading;
Content.Error = Error;

export default Content;
