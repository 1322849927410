import * as React from "react";
import { TreeSelect, TreeSelectProps } from "formik-antd";
import { uniqueId } from "lodash-es";
import { cx } from "utils";

const wait = () =>
  new Promise((resolve) => {
    requestAnimationFrame(() => requestAnimationFrame(resolve));
  });

const FormTreeSelect: React.FC<TreeSelectProps> = (props) => {
  const [visible, setVisible] = React.useState(false);
  const uniqClass = React.useMemo(() => uniqueId("dropdown"), []);

  // We should show the scrollbar when dropdown opening
  // This fix is only for TreeSelect, Select works good
  React.useLayoutEffect(() => {
    if (visible) {
      const dropdown = document.body.getElementsByClassName(uniqClass)?.[0];
      const list = dropdown.getElementsByClassName(
        "ant-select-tree-list-holder"
      )?.[0];
      if (list) {
        wait()
          .then(() => list.scrollTo(0, 1))
          .then(wait)
          .then(() => list.scrollTo(0, 0));
      }
    }
  }, [uniqClass, visible]);

  return (
    <TreeSelect
      {...props}
      dropdownClassName={cx(uniqClass, props.dropdownClassName)}
      onDropdownVisibleChange={setVisible}
      /**
       * fix dropdown moving on page scroll
       * https://github.com/ant-design/ant-design/issues/8268
       */
      getPopupContainer={(trigger) => trigger.parentNode}
    />
  );
};

export default FormTreeSelect;
