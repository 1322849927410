import * as React from "react";
import { message } from "antd";
import { To } from "react-router-dom";
import { SUCCESS_UPDATE_MESSAGE } from "consts";
import { useMutation, useNavigate, useQuery } from "hooks";
import * as Types from "types";
import { mapPolicies, onError, parseError } from "utils";
import { EditContext } from "./duck";

interface EditProps<
  TQueryData,
  TQueryVariables,
  TMutationData,
  TMutationVariables
> {
  query: Types.QueryObject<TQueryVariables> & { key?: string };
  mutation: Types.MutationObject<TMutationVariables>;
  children:
    | React.ReactNode
    | ((
        props: Types.EditConfig<
          TQueryData,
          TQueryVariables,
          TMutationData,
          TMutationVariables
        >
      ) => React.ReactNode);
  successPath?: To | number | ((data: TMutationData) => To | number) | null;
  successMessage?: ((data: TMutationData) => string) | string;
}

interface EditData {
  [key: string]: {
    id: string;
    policies?: Types.Policy[];
  };
}

function Edit<
  TQueryData extends EditData,
  TQueryVariables,
  TMutationData,
  TMutationVariables
>({
  children,
  successPath = -1,
  mutation,
  query,
  successMessage = SUCCESS_UPDATE_MESSAGE,
}: EditProps<TQueryData, TQueryVariables, TMutationData, TMutationVariables>) {
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<TQueryData>(query.operation, {
    fetchPolicy: "network-only",
    variables: query.variables,
    onCompleted: (response) => {
      query.options?.onCompleted?.(response);
    },
    onError: (queryError) => {
      /** if code 403 or 404 - just skip and show <Content.Error /> component */
      const { code } = parseError(queryError);

      if (code !== 403 && code !== 404) {
        navigate(-1);
      }
    },
  });

  const [updateRecord] = useMutation<TMutationData>(mutation.operation, {
    onCompleted: (response) => {
      if (successPath) {
        navigate(
          typeof successPath === "function"
            ? successPath(response)
            : successPath
        );
      }
      message.success(
        typeof successMessage === "function"
          ? successMessage(response)
          : successMessage
      );
    },
    onError,
    ...mutation.options,
  });

  const onSubmit = (variables: Types.OperationVariables) => {
    return updateRecord({
      variables: {
        ...mutation.variables,
        ...variables,
      },
    });
  };

  const config = {
    onSubmit,
    data,
    loading,
    error,
    policies: mapPolicies(data && Object.values(data)[0].policies),
  };

  return (
    <EditContext.Provider value={config}>
      {typeof children === "function" ? children(config) : children}
    </EditContext.Provider>
  );
}

export default Edit;
