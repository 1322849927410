import { Filter } from "types";

const isNullable = (value: any): boolean => {
  return (
    value === null ||
    value === undefined ||
    (typeof value === "string" && value === "") ||
    (Array.isArray(value) && value.length === 0)
  );
};

export const removeNullableFilters = (filter: Filter): Filter => {
  return Object.entries(filter).reduce(
    (result: Record<string, any>, current) => {
      const [operator, values] = current;

      const nonNullableValues = Object.entries(values).reduce(
        (valuesResult: Record<string, any>, currentValue) => {
          const [key, value] = currentValue;
          if (!isNullable(value)) valuesResult[key] = value;
          return valuesResult;
        },
        {}
      );

      if (Object.keys(nonNullableValues).length) {
        result[operator] = nonNullableValues;
      }

      return result;
    },
    {}
  );
};
