import * as React from "react";
import { FilterOutlined, FilterFilled, CloseOutlined } from "@ant-design/icons";
import { FormikConfig } from "formik";
import { Formik, Form, Space, Drawer, Button } from "components";
import { useList } from "hooks";
import { Filter as FilterType } from "types";
import { TextFilter, SelectFilter } from "./components";
import { utils } from "./duck";
import styles from "./Filter.module.css";

interface FilterProps {
  children?: FormikConfig<FilterType>["children"];
  defaultFilter?: FilterType;
}

const Filter = ({
  children,
  defaultFilter = {},
}: FilterProps): React.ReactElement => {
  const { filter, setFilter } = useList();
  const [isVisible, setIsVisible] = React.useState(false);
  const filtersApplied = !!(filter && Object.keys(filter).length);
  const handleSubmit = (values: FilterType) => {
    setFilter(utils.removeNullableFilters(values));
    setIsVisible(false);
    return Promise.resolve();
  };

  return (
    <>
      <Button onClick={() => setIsVisible(true)} type="link">
        {filtersApplied ? <FilterFilled /> : <FilterOutlined />}
        Filter
      </Button>

      <Formik
        initialValues={filter || {}}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <Drawer
            title="Filter"
            visible={isVisible}
            width="50%"
            placement="right"
            onClose={() => setIsVisible(false)}
            closable={false}
            footer={
              <Space className={styles.footer}>
                <Button
                  onClick={() => {
                    formikProps.resetForm({ values: defaultFilter });
                    formikProps.submitForm();
                  }}
                  loading={formikProps.isSubmitting}
                  disabled={formikProps.isSubmitting}
                >
                  Clear
                </Button>

                <Button
                  type="primary"
                  onClick={formikProps.submitForm}
                  loading={formikProps.isSubmitting}
                  disabled={formikProps.isSubmitting}
                >
                  Apply Filters
                </Button>
              </Space>
            }
            extra={
              <Button
                onClick={() => setIsVisible(false)}
                type="text"
                shape="circle"
                icon={<CloseOutlined />}
              />
            }
          >
            <Form layout="vertical" className={styles.form}>
              <div className={styles.formContent}>
                {typeof children === "function"
                  ? children(formikProps)
                  : children}
              </div>
            </Form>
          </Drawer>
        )}
      </Formik>
    </>
  );
};

Filter.Item = Form.Item;
Filter.Text = TextFilter;
Filter.Select = SelectFilter;

export default Filter;
