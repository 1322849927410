import * as Apollo from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import uploadFetch from "./upload-fetch";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  fetch: uploadFetch,
}) as unknown as Apollo.ApolloLink; // https://github.com/jaydenseric/apollo-upload-client/issues/221

const authLink = setContext((_, { headers }) => {
  const token = window.localStorage.getItem("token");
  return {
    headers: { ...headers, authorization: token ? `Bearer ${token}` : null },
  };
});

const trimStrings = new Apollo.ApolloLink((operation, forward) => {
  const isObject = (object: unknown): object is Record<string, unknown> => {
    return !!object && typeof object === "object";
  };

  const deepTrim = (object: unknown) => {
    if (isObject(object)) {
      Object.keys(object).forEach((key) => {
        object[key] = deepTrim(object[key]);
      });
    }
    if (typeof object === "string") {
      return object.trim();
    }

    return object;
  };

  deepTrim(operation.variables);

  return forward(operation);
});

const apolloClient = new Apollo.ApolloClient({
  link: Apollo.ApolloLink.from([authLink, trimStrings, httpLink]),
  cache: new Apollo.InMemoryCache(),
});

export default apolloClient;
