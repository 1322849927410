import * as React from "react";
import { FormikFieldProps } from "formik-antd/lib/FieldProps";
import { Form } from "components";
import { SelectProps } from "components/select";
import { FilterOperator } from "../../duck/types";

type SelectFilterProps = SelectProps &
  FormikFieldProps & {
    operator: FilterOperator;
  };

const SelectFilter: React.FC<SelectFilterProps> = ({
  name,
  operator,
  ...rest
}) => {
  return <Form.Select name={`${operator}.${name}`} {...rest} />;
};

export default SelectFilter;
