export * as PHONE from "./phone";

// messages
export const ERROR_MESSAGE = "Something went wrong";
export const LOGIN_ERROR_MESSAGE =
  "Oops! Looks like the error has occurred. Please reach out to support if this issue continues";
export const SUCCESS_UPDATE_MESSAGE = "Successfully updated";

// form
export const REQUIRED_ERROR_MESSAGE = "This field should not be blank.";
export const INPUT_MAX_LENGTH = {
  S: 15,
  M: 50,
  L: 255,
  VIN: 17,
  NAME: 255,
  PHONE: 18,
};
export const DEFAULT_PHONE_MIN_LENGTH = 8;

export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
export const DEFAULT_DATE_FOR_SUBMIT = "YYYY-MM-DD";

export const MESSAGES = {
  // 023
  CHANGES_SAVED: "The changes successfully saved",
};

export const MAX_NUMBER = 999999999;
