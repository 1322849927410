import * as React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import * as C from "components";
import { WidgetFC } from "../../duck/types";
import Widget from "../widget";
import mapPicture from "./assets/map.png";
import styles from "./Map.module.css";

const Map: WidgetFC = () => {
  return (
    <Widget
      title="Map"
      extra={
        <C.Button type="link">
          View Map
          <ArrowRightOutlined />
        </C.Button>
      }
    >
      <div className={styles.wrapper}>
        <img className={styles.map} src={mapPicture} alt="" />
      </div>
    </Widget>
  );
};

Map.Preview = () => (
  <Widget title="Map" preview name="map">
    <img src={mapPicture} alt="" style={{ objectFit: "cover" }} />
  </Widget>
);

export default Map;
