import * as React from "react";
import moment from "moment";
import { Typography, Chart } from "components";
import { DEFAULT_DATE_FORMAT } from "consts";
import { useQuery } from "hooks";
import Widget from "../widget";
import { Preview } from "./components";
import { operations, Types, consts } from "./duck";
import styles from "./CurrentMaintenance.module.css";

const CurrentMaintenance = (): React.ReactElement => {
  const { data, loading } = useQuery<
    Types.GetCurrentMaintenanceQuery,
    Types.GetCurrentMaintenanceQueryVariables
  >(operations.getCurrentMaintenance);

  const currentMaintenanceData = data?.widgetCurrentMaintenance;

  const fetchData = currentMaintenanceData
    ? Object.entries(currentMaintenanceData)
        // TODO: remove typename case
        .filter(([label]) => label !== "__typename")
        .map(([label, value]) => {
          return {
            id: consts.labelsByStatus[label],
            color: consts.colorsByStatus[label],
            value,
          };
        })
    : [];

  return (
    <Widget title="Current Maintenance" loading={loading}>
      <div className={styles.wrapper}>
        <Typography.Text className={styles.date} type="secondary">
          {moment(new Date()).format(DEFAULT_DATE_FORMAT)}
        </Typography.Text>

        <Chart
          type="bar"
          data={{
            nodes: fetchData,
          }}
        />
      </div>
    </Widget>
  );
};

CurrentMaintenance.Preview = Preview;

export default CurrentMaintenance;
