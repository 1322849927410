import * as React from "react";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { apolloClient } from "configs";
import { AuthProvider, AppRoutes } from "./components";

const App: React.FC = () => (
  <BrowserRouter>
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </ApolloProvider>
  </BrowserRouter>
);

export default App;
