import * as React from "react";
import { Resource } from "components";

const list = React.lazy(() => import("./components/list"));
const edit = React.lazy(() => import("./components/edit"));
const create = React.lazy(() => import("./components/create"));
const show = React.lazy(() => import("./components/show"));

const ScheduleTemplates: React.FC = () => {
  return <Resource list={list} edit={edit} create={create} show={show} />;
};

export default ScheduleTemplates;
