import * as React from "react";
import { CheckOutlined, HistoryOutlined } from "@ant-design/icons";
import { Space, ResetButton, SubmitButton } from "components";
import { useFormikContext } from "hooks";
import styles from "./FormControls.module.css";

const FormControls: React.FC = ({ children }) => {
  const { isSubmitting } = useFormikContext();

  return (
    <div className={styles.wrapper}>
      <div>{children}</div>
      <Space>
        <ResetButton icon={<HistoryOutlined />}>Reset</ResetButton>
        <SubmitButton
          icon={<CheckOutlined />}
          htmlType="submit"
          type="primary"
          /* button should be enabled when form is invalid */
          disabled={isSubmitting}
        >
          Save
        </SubmitButton>
      </Space>
    </div>
  );
};

export default FormControls;
