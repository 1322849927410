import * as React from "react";
import Widget from "../../../widget";
import preview from "./assets/preview.svg";

const Preview: React.FC = () => {
  return (
    <Widget title="In-Progress" name="workOrderByDate" preview>
      <img src={preview} alt="" />
    </Widget>
  );
};

export default Preview;
