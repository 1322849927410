import * as React from "react";
import { Content, Navigate, Route, Routes } from "components";
import * as P from "pages";
import { isAdminService } from "utils";

const AuthRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="users/*" element={<P.UserManagement />} />
      {isAdminService() ? (
        <>
          <Route path="/" element={<Navigate to="/clients" />} />
          <Route path="clients/*" element={<P.Clients />} />
        </>
      ) : (
        <>
          <Route index element={<Navigate to="/maintenances" />} />
          <Route path="/" element={<P.Home />}>
            <Route path="maintenances" element={<P.Home.Dashboard />} />
            <Route path="services" element={<P.Home.Services />} />
          </Route>
          <Route path="work-order/*" element={<P.WorkOrder />} />
          <Route
            path="schedule-templates/*"
            element={<P.ScheduleTemplates />}
          />
          <Route path="service-steps/*" element={<P.ServiceSteps />} />
          <Route path="task-groups/*" element={<P.TaskGroups />} />
          <Route path="tasks/*" element={<P.Tasks />} />
          <Route path="assets/*" element={<P.Assets />} />
          <Route path="make/*" element={<P.Make />} />
          <Route path="model/*" element={<P.Model />} />
          <Route path="region/*" element={<P.Region />} />
          <Route path="location/*" element={<P.Location />} />
          <Route path="category/*" element={<P.Category />} />
        </>
      )}

      <Route path="*" element={<Content.Error errorCode={404} />} />
    </Routes>
  );
};

export default AuthRoutes;
