import * as React from "react";
import { MenuItemProps } from "antd";
import * as C from "components";

interface ActionsLinkProps extends Omit<MenuItemProps, "children" | "onClick"> {
  path: React.ComponentProps<typeof C.Link>["to"];
  state?: React.ComponentProps<typeof C.Link>["state"];
}

const Link: React.FC<ActionsLinkProps> = ({
  path,
  state,
  children,
  ...restProps
}) => {
  return (
    <C.Menu.Item {...restProps}>
      <C.Link to={path} state={state}>
        {children}
      </C.Link>
    </C.Menu.Item>
  );
};

export default Link;
