import * as React from "react";
import { CloseOutlined } from "@ant-design/icons";
import * as C from "components";
import { consts, Types } from "../../duck";
import styles from "./EditWidgets.module.css";

interface EditWidgetsProps {
  isDrawerVisible: boolean;

  setIsDrawerVisible(visible: boolean): void;

  widgetSettings: Types.WidgetsConfig | null;

  onSubmit(selectedWidgets: Types.WidgetsConfig["selectedWidgets"]): void;

  loading: boolean;
}

const EditWidgets: React.FC<EditWidgetsProps> = ({
  isDrawerVisible,
  setIsDrawerVisible,
  widgetSettings,
  onSubmit,
  loading,
}) => {
  return (
    <C.Formik
      initialValues={
        widgetSettings
          ? Object.fromEntries(
              widgetSettings.selectedWidgets.map((name) => [name, true])
            )
          : {}
      }
      onSubmit={(values) => {
        const selectedWidgets = Object.entries(values).reduce((a, c) => {
          const [name, selected] = c;
          if (selected) {
            a.push(name);
          }
          return a;
        }, [] as Types.WidgetName[]);

        onSubmit(selectedWidgets);
      }}
      enableReinitialize
    >
      {({ submitForm, resetForm }) => (
        <C.Drawer
          width={422}
          title="Widgets"
          visible={isDrawerVisible}
          onClose={() => setIsDrawerVisible(false)}
          closable={false}
          extra={
            <C.Button
              onClick={() => setIsDrawerVisible(false)}
              type="text"
              shape="circle"
              icon={<CloseOutlined />}
            />
          }
          footer={
            <C.Space className={styles.drawerFooter}>
              <C.Button
                onClick={() => {
                  resetForm();
                  setIsDrawerVisible(false);
                }}
                loading={loading}
                disabled={loading}
              >
                Cancel
              </C.Button>

              <C.Button
                type="primary"
                onClick={submitForm}
                loading={loading}
                disabled={loading}
              >
                Ok
              </C.Button>
            </C.Space>
          }
        >
          {consts.widgetList.map((w) => {
            return React.createElement(
              consts.WidgetComponents[w.name].Preview,
              {
                key: w.name,
              }
            );
          })}
        </C.Drawer>
      )}
    </C.Formik>
  );
};

export default EditWidgets;
