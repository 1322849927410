import * as React from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { omit } from "lodash-es";
import { Carousel } from "components";
import { useMutation, useAuth, useQuery } from "hooks";
import { isModusAdmin } from "utils";
import { EditWidgets } from "./components";
import { Types, operations, consts, utils } from "./duck";
import styles from "./Widgets.module.css";

interface WidgetsProps {
  isDrawerVisible: boolean;

  setIsDrawerVisible(visible: boolean): void;
}

const PrevArrow: React.FC = (props) => (
  // https://github.com/akiran/react-slick/issues/623#issuecomment-629764816
  <button type="button" {...omit(props, ["currentSlide", "slideCount"])}>
    <LeftOutlined />
  </button>
);

const NextArrow: React.FC = (props) => (
  <button type="button" {...omit(props, ["currentSlide", "slideCount"])}>
    <RightOutlined />
  </button>
);

const Widgets: React.FC<WidgetsProps> = ({
  isDrawerVisible,
  setIsDrawerVisible,
}) => {
  const [{ user }] = useAuth();
  const userQuery =
    user && isModusAdmin(user.role.id)
      ? operations.getAdminWidgetSettings
      : operations.getClientWidgetSettings;

  const { data, previousData, error } = useQuery<
    Types.GetWidgetSettings,
    Types.GetWidgetSettingsVariables
  >(userQuery, {
    variables: {
      id: user?.id || "", // user can't be null there
    },
  });

  const [updateWidgetSettings, { loading: updateLoading }] = useMutation<
    Types.UpdateWidgetSettingsMutation,
    Types.UpdateWidgetSettingsMutationVariables
  >(operations.updateWidgetSettings, {
    refetchQueries: [
      {
        query: userQuery,
        variables: { id: user?.id || "" },
      },
    ],
  });

  if (!user || error) return null;

  /**
   * loading:
   * think because default widgets list is empty
   * we shouldn't show preloader */

  const widgetSettings = utils.getWidgetSettings(data, previousData);

  return (
    <>
      {widgetSettings && widgetSettings.selectedWidgets?.length ? (
        <Carousel
          arrows
          slidesToShow={4}
          slidesToScroll={1}
          infinite={false}
          responsive={[
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 1275, // 1280 - mac 13
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 780,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          className={styles.wrapper}
        >
          {widgetSettings.selectedWidgets.map((name) => {
            return (
              consts.WidgetComponents[name] && (
                <div className={styles.widgetItem} key={name}>
                  {React.createElement(consts.WidgetComponents[name])}
                </div>
              )
            );
          })}
        </Carousel>
      ) : null}

      <EditWidgets
        isDrawerVisible={isDrawerVisible}
        setIsDrawerVisible={setIsDrawerVisible}
        widgetSettings={widgetSettings}
        onSubmit={(selectedWidgets) => {
          const newSettings = {
            ...widgetSettings,
            selectedWidgets,
          };

          setIsDrawerVisible(false);

          updateWidgetSettings({
            variables: {
              id: user.id,
              widgetSettings: newSettings,
            },
          });
        }}
        loading={updateLoading}
      />
    </>
  );
};

export default Widgets;
