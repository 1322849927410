import * as React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Typography } from "components";
import styles from "./Import.module.css";

const Import: React.FC = () => {
  return (
    <>
      <Typography.Text>
        STEP 2: Upload a CSV file for the Assets that should be added.
      </Typography.Text>
      <div className={styles.uploadContainer}>
        <Form.Item name="files">
          <Form.Upload
            name="files"
            accept=".csv"
            maxCount={1}
            className={styles.upload}
            withoutRequest
          >
            <Button type="primary" icon={<UploadOutlined />} ghost>
              Upload
            </Button>
          </Form.Upload>
        </Form.Item>
      </div>
    </>
  );
};

export default Import;
