import * as React from "react";
import { Man, Woman, Gear, Lock } from "./assets";
import styles from "./Img403.module.css";

const Img403 = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <img src={Man} alt="" />
        <img className={styles.lock} src={Lock} alt="" />
      </div>

      <div className={styles.right}>
        <img src={Woman} alt="" />
        <img className={styles.gear} src={Gear} alt="" />
      </div>
    </div>
  );
};

export default Img403;
