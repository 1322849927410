import { useLocation, useResolvedPath } from "react-router-dom";

const useChildPath = (): string => {
  /**
   *  returns variable part of nested route:
   *  Path pattern: "/parentPath/*" returns * as childPath
   *  For nested Routes:
   *  <Route path="/parentPath">
   *     <Route path="childPath" />
   *  </Route>
   */
  const location = useLocation();
  const parentPath = useResolvedPath("");

  return location.pathname.slice(parentPath.pathname.length).replace(/^\//, "");
};

export default useChildPath;
