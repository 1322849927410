import { useMutation as useNativeMutation } from "@apollo/client";
import useOnError from "./use-on-error";

const useMutation: typeof useNativeMutation = (mutation, options) => {
  const onError = useOnError(options?.onError);

  return useNativeMutation(mutation, { ...options, onError });
};

export default useMutation;
