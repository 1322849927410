import PhoneNumber from "awesome-phonenumber";
import { PHONE } from "consts";

export const applyMask = (input = "", mask?: string): string => {
  const cleanInput = input.replace(/[^\d]/g, "");
  if (!mask) return cleanInput;
  const result: string[] = [];
  let i = 0;

  mask.split("").forEach((p) => {
    if (!cleanInput[i]) return;

    if (p === ".") {
      result.push(cleanInput[i]);
      i += 1;
    } else {
      result.push(p);
    }
  });

  return result.join("");
};

export const formatPhoneNumber = (number: string, code: string): string => {
  const mask = PHONE.masks[code];
  return applyMask(number, mask);
};

export const parse = (
  phone: string
): { code: string; number: string; isoCode: string } => {
  const countryCode = PhoneNumber(phone).getCountryCode();
  const isoCode = PhoneNumber(phone).getRegionCode() || "";
  const code = countryCode ? `+${countryCode}` : "";
  const number = formatPhoneNumber(phone.slice(code.length), code);

  return {
    code,
    number,
    isoCode: isoCode.toLowerCase(),
  };
};

export const prepare = (phoneNumber: {
  code?: string;
  number: string;
}): string => {
  return PhoneNumber(`${phoneNumber.code}${phoneNumber.number}`).getNumber(
    "e164"
  );
};
