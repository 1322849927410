import * as Types from "types";

const PolicyNames: Record<string, Types.PolicyTypes> = {
  AdminUser: "adminUser",
  Asset: "asset",
  AssetSchedule: "assetSchedule",
  AssetHistory: "assetHistory",
  AssetSpec: "assetSpec",
  Client: "client",
  ClientUser: "clientUser",
  DashboardService: "dashboardService",
  Maintenance: "maintenance",
  Model: "model",
  Region: "region",
  Role: "role",
  Schedule: "schedule",
  ScheduleStep: "scheduleStep",
  Service: "service",
  Step: "step",
  TaskGroup: "taskGroup",
  Task: "task",
  UserManagement: "userManagement",
  WorkOrder: "workOrder",
  WorkOrderTask: "workOrderTask",
  Make: "make",
  Location: "location",
  Category: "category",
};

const PolicyActions: Record<string, Types.ClientActions> = {
  "create?": "create",
  "update?": "update",
  "destroy?": "delete",
  "show?": "show",

  // assets
  "import_assets?": "assetsBulkUpload",

  // tasks
  "import?": "tasksBulkUpload",

  // clients
  "signin_as_client?": "signInAsClient",
};

type ActionType = Record<Types.ClientActions, boolean>;

type PolicyType = Record<Types.PolicyTypes, ActionType>;

export const mapPolicies = (policies?: Types.Policy[]): PolicyType => {
  if (!policies) return {} as PolicyType;

  return policies.reduce((result, policy) => {
    if (policy.name in PolicyNames) {
      result[PolicyNames[policy.name]] = policy.policyRules.reduce(
        (resultRules, rule) => {
          if (rule.action in PolicyActions) {
            resultRules[PolicyActions[rule.action]] = rule.allow;
          }

          return resultRules;
        },
        {} as ActionType
      );
    }

    return result;
  }, {} as PolicyType);
};

export const isAdminService = () => process.env.REACT_APP_ADMIN === "true";
export const isModusAdmin = (roleId: string) =>
  roleId === "4" || roleId === "5";
