import * as React from "react";
import * as ReactDOM from "react-dom";
// Global style settings.
import "antd/dist/antd.less";
import "./index.css";
// Configs and app root.
import "configs/yup";
import App from "./app";

ReactDOM.render(<App />, document.getElementById("root"));
