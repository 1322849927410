import * as React from "react";
import { Select as AntSelect } from "antd";
import { SelectProps as AntSelectProps } from "antd/lib/select";
import { QuerySelect } from "./components";
import { Types } from "./duck";

export type SelectProps<
  TOption = Record<string, any>,
  TVariables = Record<string, any>
> = Omit<AntSelectProps<any>, "options"> & {
  optionValue?: keyof TOption;
  optionLabel?: keyof TOption;
} & Types.SimpleOrQuerySelect<TOption, TVariables>;

function Select<
  TOption extends Record<string, any>,
  TVariables extends Record<string, any>
>({
  getPopupContainer = (trigger) => trigger.parentNode,
  query,
  optionValue = "value",
  optionLabel = "label",
  options,
  ...props
}: SelectProps<TOption, TVariables>): React.ReactElement {
  if (query) {
    return (
      <QuerySelect
        getPopupContainer={getPopupContainer}
        query={query}
        optionValue={optionValue}
        optionLabel={optionLabel}
        {...props}
      />
    );
  }

  return (
    <AntSelect
      {...props}
      getPopupContainer={getPopupContainer}
      options={options?.map((opt) => ({
        label: opt[optionLabel],
        value: opt[optionValue],
        key: opt[optionValue],
      }))}
    />
  );
}

Select.Option = AntSelect.Option;

export default Select;
