import * as React from "react";
import { LogoutOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import LOGOS from "assets/logos";
import { Avatar, Button, Typography } from "components";
import { consts } from "components/admin/list/duck";
import { useAuth, useMutation, useNavigate, usePolicies } from "hooks";
import { isAdminService, isModusAdmin } from "utils";
import { operations, Types } from "./duck";
import styles from "./AppHeader.module.css";

const AppHeader: React.FC = () => {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const policies = usePolicies();
  const [, setSearchParams] = useSearchParams();

  const [signOutAsClient] = useMutation<
    Types.SignOutAsClientMutation,
    Types.SignOutAsClientMutationVariables
  >(operations.signOutAsClient);

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <img src={LOGOS.header} alt="Logo" className={styles.logo} />
        {auth.isLoggedIn && <div className={styles.verticalLine} />}
      </div>
      {auth.isLoggedIn && (
        <div className={styles.authWrapper}>
          <div className={styles.userWrapper}>
            <Avatar size={30} icon={<UserOutlined />} />
            {auth.user && (
              <Typography.Title className={styles.name} level={5}>
                {`${auth.user.firstName} ${auth.user.lastName}`}
              </Typography.Title>
            )}

            {isAdminService() && policies?.clients?.create && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate("/clients/create")}
              >
                Create Account
              </Button>
            )}
          </div>

          {auth.user && isModusAdmin(auth.user.role.id) && !isAdminService() ? (
            <Button
              onClick={() => {
                signOutAsClient().then((response) => {
                  if (response.data) {
                    // not auth.signOut - to avoid switching to /login. Just invalidate token and go to admin-app
                    localStorage.removeItem("token");
                    window.location.replace(
                      `${process.env.REACT_APP_PMS_ADMIN_URL}?token=${response.data.signOutAsClient.authToken}`
                    );
                  }
                });
              }}
              icon={<LogoutOutlined />}
              type="ghost"
            >
              Go Back
            </Button>
          ) : (
            <Button
              onClick={() => {
                setSearchParams({});
                consts.paramsCache.clear();

                auth.signOut();
              }}
              type="primary"
              icon={<LogoutOutlined />}
              ghost
            >
              Log Out
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default AppHeader;
