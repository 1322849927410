import * as React from "react";
import * as C from "components";
import { INPUT_MAX_LENGTH } from "consts";
import { FormItemProps } from "../../duck/types";

const LastName: React.FC<FormItemProps> = ({ md }) => {
  return (
    <C.Col span={24} md={md}>
      <C.Form.Item name="lastName" label="Last Name" required>
        <C.Form.Input
          name="lastName"
          placeholder="Enter Last Name"
          maxLength={INPUT_MAX_LENGTH.L}
        />
      </C.Form.Item>
    </C.Col>
  );
};

export default LastName;
