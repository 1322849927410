import * as React from "react";
import { Form } from "components";
import { INPUT_MAX_LENGTH, PHONE } from "consts";
import { useField } from "hooks";
import { phoneUtils } from "utils";
import CountryFlag from "../flag";
import styles from "./PhoneNumber.module.css";

interface PhoneNumberInputProps {
  name: string;
  placeholder?: string;
}

const OPTIONS = Object.values(PHONE.countries).map((c) => ({
  value: c.isoCode,
  label: (
    <div className={styles.dropdown} key={`${c.name} ${c.code} ${c.isoCode}`}>
      {/* eslint-disable-next-line css-modules/no-undef-class */}
      <CountryFlag isoCode={c.isoCode} className={styles.flag} />{" "}
      {/* eslint-disable-next-line css-modules/no-undef-class */}
      <span className={styles.name}>{c.name}</span>
      {/* eslint-disable-next-line css-modules/no-undef-class */}
      <span className={styles.code}>{c.code}</span>
    </div>
  ),
}));

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  name,
  placeholder,
}) => {
  const [{ value }, , { setValue }] = useField(name);
  const inputRef = React.createRef<any>();

  const inputAddonBefore = (
    <Form.Select
      name={`${name}.isoCode`}
      options={OPTIONS}
      showSearch
      filterOption={(input, option) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        option?.label?.key.toLowerCase().indexOf(input.toLowerCase()) !== -1
      }
      onChange={(isoCode) => {
        const { code } = PHONE.countries[isoCode];
        setValue({
          code,
          isoCode,
          number: phoneUtils.formatPhoneNumber(value.number, code),
        });
        inputRef.current?.focus();
      }}
      dropdownMatchSelectWidth={false}
      style={{ width: 65 }}
    />
  );

  // TODO: process number on paste/browser-autocomplete (parse, fill select and input with values)
  return (
    <Form.Input
      className={styles.wrapper}
      ref={inputRef}
      name={`${name}.number`}
      placeholder={placeholder}
      maxLength={INPUT_MAX_LENGTH.PHONE}
      onChange={(event) => {
        setValue({
          ...value,
          number: phoneUtils.formatPhoneNumber(
            event.currentTarget.value,
            value.code
          ),
        });
      }}
      prefix={value.code}
      addonBefore={inputAddonBefore}
    />
  );
};

export default PhoneNumberInput;
