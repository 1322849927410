import * as React from "react";
import Widget from "../../../widget";
import preview from "./assets/preview.svg";

const Preview: React.FC = () => {
  return (
    <Widget title="Past Due" preview name="pastDue">
      <img src={preview} alt="" />
    </Widget>
  );
};

export default Preview;
