import * as React from "react";
import { Form } from "components";
import { INPUT_MAX_LENGTH } from "consts";

const Unit: React.FC = () => {
  return (
    <Form.Item name="unit" label="Unit">
      <Form.Input
        name="unit"
        placeholder="Unit"
        maxLength={INPUT_MAX_LENGTH.L}
      />
    </Form.Item>
  );
};

export default Unit;
