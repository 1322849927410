import * as React from "react";
import { ApolloError } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { message } from "components";
import { ERROR_MESSAGE, LOGIN_ERROR_MESSAGE } from "consts";
import { parseError } from "utils";
import useAuth from "../use-auth";

export default (onError?: (error: ApolloError) => void) => {
  const [auth] = useAuth();
  const location = useLocation();
  const defaultMessage =
    location.pathname === "/login" ? LOGIN_ERROR_MESSAGE : ERROR_MESSAGE;

  return React.useCallback(
    (error: ApolloError) => {
      // Catch errors
      const { messages, code } = parseError(error);

      if (code !== 404) message.error(messages[0] || defaultMessage);
      if (code === 401) auth.signOut();

      // By Default
      if (onError) {
        onError(error);
      } else {
        throw error;
      }
    },
    [defaultMessage, auth, onError]
  );
};
