import { PossibleLabels } from "./types";

export const colorsByStatus: { [key in PossibleLabels | string]: string } = {
  pastDue: "#FF4D4F",
  comingDue: "#FFA940",
  all: "#595959",
  total: "",
};

export const labelsByStatus: { [key in PossibleLabels | string]: string } = {
  pastDue: "Past Due",
  comingDue: "Coming Due",
  all: "All Others",
};
