import axios, { Canceler } from "axios";

export interface BaseFetchOptions extends RequestInit {
  headers: Record<string, string>;
}

export interface ProgressOptions {
  onProgress?: (event: ProgressEvent<EventTarget>) => void;
  onAbort?: (canceler: Canceler) => void;
}

export type FetchOptions = BaseFetchOptions & ProgressOptions;

function getUrl(input: RequestInfo) {
  if (typeof input === "string") {
    return input;
  }

  if (input.url) {
    return input.url;
  }

  return "";
}

// https://github.com/jaydenseric/apollo-upload-client/issues/88#issuecomment-384166141
const uploadFetch = (input: RequestInfo, options: FetchOptions) => {
  if (options.body instanceof FormData) {
    return new Promise<Response>((resolve, reject) => {
      const cancelTokenSource = axios.CancelToken.source();

      axios
        .post(getUrl(input), options.body, {
          method: "POST",
          headers: options.headers,
          onUploadProgress: options.onProgress,
          cancelToken: cancelTokenSource.token,
        })
        .then((response) => {
          resolve(new Response(JSON.stringify(response.data), response));
        })
        .catch(reject);

      options.onAbort?.(cancelTokenSource.cancel);
    });
  }

  return fetch(input, options);
};

export default uploadFetch;
