import * as React from "react";
import * as C from "components";
import { FormItemProps } from "../../duck/types";
import { operations } from "./duck";

const Role: React.FC<FormItemProps & { variables: { admin: boolean } }> = ({
  md,
  variables,
}) => {
  return (
    <C.Col span={24} md={md}>
      <C.Form.Item name="roleId" label="Role" required>
        <C.Form.Select
          name="roleId"
          optionValue="id"
          optionLabel="name"
          placeholder="Select Role"
          query={{
            operation: operations.getRolesForClientUsers,
            variables,
            key: "roles",
          }}
        />
      </C.Form.Item>
    </C.Col>
  );
};

export default Role;
