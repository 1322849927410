import * as L from "../components";
import * as Types from "./types";

export const WidgetComponents: Types.WidgetComponents = {
  pastDue: L.PastDue,
  workOrderByStatus: L.WorkOrderByStatus,
  workOrderByDate: L.WorkOrderByDate,
  currentMaintenance: L.CurrentMaintenance,
};

export const widgetDefaultConfig: Types.WidgetsConfig = {
  selectedWidgets: [],
};

export const widgetList: Types.WidgetList = [
  {
    name: "pastDue",
  },
  {
    name: "workOrderByStatus",
  },
  {
    name: "workOrderByDate",
  },
  {
    name: "currentMaintenance",
  },
];
