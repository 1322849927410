import * as React from "react";
import { Resource } from "components";

const list = React.lazy(() => import("./components/list"));
const show = React.lazy(() => import("./components/show"));
const create = React.lazy(() => import("./components/create"));
const edit = React.lazy(() => import("./components/edit"));

const UserManagement: React.FC = () => {
  return <Resource list={list} show={show} create={create} edit={edit} />;
};

export default UserManagement;
