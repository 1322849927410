import { filter } from "lodash-es";
import { Region, Location, RegionLocation } from "./types";

export const getIds = (
  region: string,
  locations: Location[] = [],
  path: "id" | "name"
) =>
  locations.length
    ? locations.map((location) => `${region}-${location[path]}`)
    : [region];

const filterLocations = (regionId: string, locations: Location[]) =>
  filter(locations, (location) => location.region?.id === regionId);

export const sliceRegionsAndLocationsIds = (regionsLocations: string[]) => {
  return regionsLocations.reduce<RegionLocation>(
    (acc, cur) => {
      const [regionId, locationId] = cur.split("-");

      if (regionId && !acc.regionIds.includes(regionId))
        acc.regionIds.push(regionId);

      if (locationId && !acc.locationIds.includes(locationId))
        acc.locationIds.push(locationId);

      return acc;
    },
    {
      locationIds: [],
      regionIds: [],
    }
  );
};

export const getInitialPlaces = (
  regions: Region[],
  locations: Location[],
  all?: boolean
) =>
  regions.reduce<string[]>(
    (acc, cur) => {
      const locationsByRegion = filterLocations(cur.id, locations);

      return [...acc, ...getIds(cur.id, locationsByRegion, "id")];
    },
    all ? ["all"] : []
  );

export const prepareRegionsLocations = (regionsLocations: string[]) => {
  if (!regionsLocations.length) return { locationIds: [], regionIds: [] };

  return sliceRegionsAndLocationsIds(
    filter(regionsLocations, (item) => item !== "all")
  );
};

export const getPlacesName = (regions: Region[], locations: Location[]) =>
  regions.reduce<string[]>((acc, cur) => {
    const locationsByRegion = filterLocations(cur.id, locations);

    return [...acc, ...getIds(cur.name, locationsByRegion, "name")];
  }, []);
