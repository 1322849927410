import * as yup from "yup";
import { FormValues } from "./types";

export const validationSchema: yup.SchemaOf<FormValues> = yup.object({
  name: yup.string().trim().required(),
  unit: yup.string().nullable(),
  taskGroupIds: yup
    .array()
    // https://github.com/jquense/yup/issues/1367 (yup.string().required() results in "string | undefined")
    .of(yup.string().required() as yup.StringSchema<string>),
});
