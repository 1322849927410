import React from "react";
import { Chart as ChartJs, registerables } from "chart.js";
import { Doughnut, Bar, HalfDoughnut } from "./components";
import { Types } from "./duck";

ChartJs.register(...registerables);

interface ChartProps {
  type: "doughnut" | "bar" | "halfDoughnut";
  data: Types.DataChart;
}

const Chart: React.FC<ChartProps> = ({ type, data }) => {
  return (
    <>
      {type === "doughnut" && <Doughnut data={data} />}
      {type === "bar" && <Bar data={data} />}
      {type === "halfDoughnut" && <HalfDoughnut data={data} />}
    </>
  );
};

export default Chart;
