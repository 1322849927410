import * as React from "react";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import PhoneNumber from "awesome-phonenumber";
import { FieldProps, FormikConfig } from "formik";
import * as C from "components";
import { INPUT_MAX_LENGTH } from "consts";
import { useMutation } from "hooks";
import { cx, isAdminService, phoneUtils } from "utils";
import { consts, operations, Types } from "./duck";
import styles from "./Credentials.module.css";

interface CredentialsProps {
  onSuccess: (data: Omit<Types.Credentials, "code">) => void;
  countryCode: string;
}

const Credentials: React.FC<CredentialsProps> = ({
  onSuccess,
  countryCode,
}) => {
  const [verifyUser] = useMutation<
    Types.VerifyUserMutation,
    Types.VerifyUserMutationVariables
  >(
    isAdminService() ? operations.verifyAdminUser : operations.verifyClientUser
  );

  const login: FormikConfig<Types.Credentials>["onSubmit"] = async (
    values,
    { setSubmitting }
  ) => {
    const { email, phoneNumber } = {
      email: values.email,
      phoneNumber:
        values.phoneNumber &&
        PhoneNumber(`${countryCode}${values.phoneNumber}`).getNumber("e164"),
    };

    verifyUser({
      variables: {
        credentials: {
          ...(email ? { email } : {}),
          ...(phoneNumber ? { phoneNumber } : {}),
        },
      },
    })
      .then(() => {
        onSuccess({ email, phoneNumber });
        C.message.success("Code sent successfully");
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <div>
        <C.Typography.Title level={5}>
          Please enter your mobile phone or email to receive your login passcode
        </C.Typography.Title>
      </div>

      <C.Formik
        validationSchema={consts.validationSchema}
        initialValues={{
          ...consts.initialValues,
          code: countryCode,
        }}
        onSubmit={login}
        key={countryCode}
      >
        {({
          values,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          isSubmitting,
        }) => {
          const { phoneNumber, email } = values;
          const phoneDisabled = !!email;
          const emailDisabled = !!phoneNumber;

          return (
            <C.Form className={styles.form} layout="vertical">
              <C.Form.Item
                name="phoneNumber"
                wrapperCol={{ span: 14, offset: 5 }}
              >
                <C.Field name="phoneNumber">
                  {({ field: { onBlur, value, name } }: FieldProps<string>) => {
                    return (
                      <C.Form.Input
                        maxLength={INPUT_MAX_LENGTH.PHONE}
                        name={name}
                        prefix={
                          <C.Space>
                            <PhoneOutlined className="site-form-item-icon" />
                            <C.Typography.Text
                              disabled={phoneDisabled || isSubmitting}
                            >
                              {countryCode}
                            </C.Typography.Text>
                          </C.Space>
                        }
                        placeholder="Enter Phone Number"
                        disabled={phoneDisabled}
                        onChange={(event) => {
                          const newValue = event.currentTarget.value;
                          setFieldValue(
                            "phoneNumber",
                            phoneUtils.formatPhoneNumber(newValue, countryCode)
                          );
                        }}
                        value={value}
                        onBlur={onBlur}
                        onFocus={() => {
                          setFieldTouched("phoneNumber");
                          setFieldTouched("email", false);
                          setFieldError("email", undefined);
                        }}
                      />
                    );
                  }}
                </C.Field>
              </C.Form.Item>

              <div className={styles.or}>
                <C.Typography.Text>or</C.Typography.Text>
              </div>

              <C.Form.Item
                name="email"
                wrapperCol={{ span: 14, offset: 5 }}
                validateFirst="parallel"
              >
                <C.Form.Input
                  name="email"
                  placeholder="Enter Email"
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  disabled={emailDisabled}
                  onFocus={() => {
                    setFieldTouched("phoneNumber", false);
                    setFieldError("phoneNumber", undefined);
                  }}
                />
              </C.Form.Item>
              <C.Space className={cx(styles.hCenter, styles.actions)}>
                <C.SubmitButton
                  htmlType="submit"
                  disabled={!(phoneNumber || email)}
                >
                  Log In
                </C.SubmitButton>
              </C.Space>
            </C.Form>
          );
        }}
      </C.Formik>
    </>
  );
};

export default Credentials;
