import * as React from "react";
import { Space, Typography } from "components";

interface DoneProps {
  uploadedCount: number;
  notUploadedCount: number;
}

const Done: React.FC<DoneProps> = ({ uploadedCount, notUploadedCount }) => {
  return (
    <Space direction="vertical">
      <Typography>Uploaded: {uploadedCount}</Typography>
      <Typography>Not Uploaded: {notUploadedCount}</Typography>
    </Space>
  );
};

export default Done;
