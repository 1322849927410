import * as Types from "./types";

export const policies: Types.RolePolicies = {
  /** https://primary-maintenance.atlassian.net/browse/PMS-279 */
  // pms
  // Admin
  "1": {},
  // Manager
  "2": {},
  // General_User
  "3": {},

  // admin
  // Modus_Admin
  "4": {
    clients: {
      create: true,
    },
  },
  // Modus_User
  "5": {
    clients: {
      create: false,
    },
  },
};
