import * as React from "react";
import { cx } from "utils";
import styles from "./Flag.module.css";

interface FlagProps {
  isoCode: string;
  className?: string;
}

const Flag: React.FC<FlagProps> = ({ isoCode, className }) => {
  return <div className={cx(styles.wrapper, styles[isoCode], className)} />;
};

export default Flag;
