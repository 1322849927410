import * as yup from "yup";
import * as G from "consts";
import { REQUIRED_ERROR_MESSAGE } from "consts";
import { isAdminService } from "utils";

export const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: {
    number: "",
    ...G.PHONE.defaultCountry,
  },
  regionsLocations: [],
  allRegions: true,
  roleId: isAdminService() ? "5" : "3", // isAdminService ? Modus_User : General_User
  active: true,
};

export const validationSchema = yup.lazy((values) => {
  const phoneMask = G.PHONE.masks[values.phoneNumber.code];
  const phoneLength = phoneMask
    ? phoneMask.replace(/[^.]/g, "").length
    : G.DEFAULT_PHONE_MIN_LENGTH;

  return yup.object({
    firstName: yup.string().trim().nullable().required(),
    lastName: yup.string().trim().nullable().required(),
    email: yup.string().trim().email("Invalid Email").required(),
    regionsLocations: yup
      .array()
      .of(yup.string())
      .min(1, REQUIRED_ERROR_MESSAGE),
    phoneNumber: yup.object({
      code: yup.string(),
      number: yup
        .string()
        .nullable()
        .trim()
        .required()
        .test(
          "length",
          `Phone number must contain ${phoneLength} characters`,
          (val) => {
            if (!val) return false;
            return val.replace(/[^\d]/g, "").length >= phoneLength;
          }
        ),
    }),
    roleId: yup.string(),
    active: yup.boolean().required(),
  });
});
