import * as React from "react";
import { Resource } from "components";

const list = React.lazy(() => import("./components/list"));
const show = React.lazy(() => import("./components/show"));
const edit = React.lazy(() => import("./components/edit"));
const create = React.lazy(() => import("./components/create"));

const Clients: React.FC = () => {
  return <Resource list={list} show={show} edit={edit} create={create} />;
};

export default Clients;
