import * as React from "react";
import { Resource } from "components";

const list = React.lazy(() => import("./components/list"));
const create = React.lazy(() => import("./components/create"));
const edit = React.lazy(() => import("./components/edit"));
const show = React.lazy(() => import("./components/show"));

const Make: React.FC = () => {
  return <Resource list={list} edit={edit} create={create} show={show} />;
};

export default Make;
